<template>
	<AppHeader v-if="isMobile && isPortrait && route.name !== 'WebAR'" />
	<DesktopNotification v-if="!isMobile" />
	<AppCharacter3D v-if="isMobile && isPortrait" />
	<RouterView v-if="isMobile && isPortrait" />
	<SvgSymbol v-if="isMobile && isPortrait" />
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from 'vue'
import { RouterView, useRouter, useRoute } from 'vue-router'
import AppHeader from '@/components/AppHeader.vue'
import SvgSymbol from '@/components/SvgSymbol.vue'
import DesktopNotification from '@/components/DesktopNotification.vue'
import AppCharacter3D from '@/components/3D/AppCharacter3d.vue'
import { isMobile as checkIsMobile } from '@/helpers/isMobile'
import useOrientationHelper from './composables/useOrientationHelper'
import useAuthHandler from '@/composables/useAuthHandler.js'

const isMobile = ref(true)
const { isPortrait } = useOrientationHelper()
const router = useRouter()
const route = useRoute()

useAuthHandler()

onBeforeMount(() => {
	let isLogin = true

	if (!isLogin) {
		router.push('/login')
	}
})

onMounted(() => {
	if (!checkIsMobile.any()) {
		isMobile.value = false
	}
})
</script>
