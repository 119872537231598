<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="178"
		height="178"
		viewBox="0 0 178 178"
		fill="none"
	>
		<circle cx="89" cy="89" r="88.5" fill="white" fill-opacity="0.2" stroke="white" />
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M94 62H84V84H62V94H84V116H94V94H116V84H94V62Z"
			fill="white"
		/>
	</svg>
</template>
