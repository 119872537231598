import { getOptions, HOST, request } from './index'

export const getCurrency = async (authHeaders) => {
	const url = `${HOST}/profile/currency`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders)
		})
		if (response.ok) {
			const data = await response.json()
			return data
		}
	})
}
