import { getOptions, HOST, request } from './index'

export const getVariation = async (authOptions, characterId, variationId) => {
	const url = `${HOST}/rigs/${characterId}/variations/${variationId}`
	return request(async () => {
		const response = await fetch(url, getOptions(authOptions))
		if (response.ok) {
			const data = await response.json()
			return data
		}
	})
}

export const getVariationById = async (authOptions, characterId) => {
	const url = `${HOST}/characters/${characterId}`
	return request(async () => {
		const response = await fetch(url, getOptions(authOptions))
		if (response.ok) {
			const data = await response.json()
			return data
		}
	})
}

export const postVariation = async (authOptions, body) => {
	const url = `${HOST}/characters/get_variation`
	const options = {
		...getOptions(authOptions),
		method: 'POST',
		body
	}
	return request(async () => {
		const response = await fetch(url, options)
		if (response.ok) {
			const data = await response.json()
			return data
		}
	})
}

export const buyCharacterById = async (authOptions, variationId) => {
	const url = `${HOST}/characters/${variationId}/buy`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authOptions),
			method: 'POST'
		})

		const data = await response.json()
		return data
	})
}

export const buyAnimationById = async (authOptions, animationId, body) => {
	const url = `${HOST}/animations/${animationId}/buy`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authOptions),
			method: 'POST',
			body
		})

		const data = await response.json()
		return data
	})
}

export const sellCharacterById = async (authOptions, variationId) => {
	const url = `${HOST}/characters/${variationId}/sell`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authOptions),
			method: 'POST'
		})
		if (response.ok) {
			const data = await response.json()
			return data
		}
	})
}
