<template>
	<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.0625 14.1008C21.0627 13.9864 21.0404 13.873 20.9967 13.7672C20.953 13.6614 20.8889 13.5653 20.808 13.4844C20.727 13.4034 20.6309 13.3393 20.5251 13.2956C20.4193 13.2519 20.306 13.2296 20.1915 13.2299H19.7883C19.5466 13.2386 19.3051 13.2094 19.0725 13.1432C18.873 13.0208 18.6957 12.8654 18.5484 12.6835L17.0826 11.1049L16.5322 12.9657L16.6673 13.1674L18.0423 14.6956C18.3971 15.0504 19.0302 14.9698 19.0302 14.9698H20.2399C20.4622 14.9579 20.6715 14.8611 20.8246 14.6994C20.9776 14.5377 21.0628 14.3235 21.0625 14.1008Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M14.4351 18.4797L13.4089 20.7176C13.2694 20.9661 13.0411 21.1526 12.7698 21.2398H12.7597C12.5613 21.291 12.359 21.3254 12.1549 21.3426L8.81615 21.3708C8.58623 21.3708 8.36572 21.2795 8.20314 21.1169C8.04056 20.9543 7.94922 20.7338 7.94922 20.5039C7.94922 20.274 8.04056 20.0535 8.20314 19.8909C8.36572 19.7283 8.58623 19.637 8.81615 19.637L11.5137 19.6067C11.652 19.611 11.7885 19.5752 11.9069 19.5036C12.0253 19.4321 12.1205 19.3279 12.1811 19.2035L12.6085 18.3325L14.4351 18.4797Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M5.78591 10.0806H4.07825C3.48954 10.0806 3.48954 10.9959 4.07825 10.9959H5.78591C6.37462 10.9959 6.37462 10.0806 5.78591 10.0806Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M19.6537 19.8628L17.3573 17.4072C17.2063 17.2692 17.0391 17.15 16.8593 17.0523C16.6651 16.9643 16.4555 16.9157 16.2424 16.9092H15.1617L17.2182 12.4273C17.3647 12.1078 17.3784 11.7432 17.2563 11.4136C17.1342 11.0839 16.8863 10.8162 16.567 10.6693L15.573 10.2116C15.3974 10.1273 15.2054 10.0825 15.0105 10.0806H6.99036C6.40165 10.0806 6.40165 10.9959 6.99036 10.9959H10.4178L9.29278 12.2056C9.16584 12.3761 9.10582 12.5872 9.12407 12.799C9.14232 13.0108 9.23759 13.2086 9.39183 13.3549C9.54607 13.5012 9.74859 13.5859 9.96107 13.5929C10.1735 13.5999 10.3812 13.5288 10.5448 13.3931L11.5992 12.256C12.065 11.7902 12.3291 11.8225 12.3291 11.8225H14.0791C14.6678 11.8225 14.6658 12.7378 14.0791 12.7378H12.0408C11.4521 12.7378 11.4521 13.6511 12.0408 13.6511H12.5408L11.45 15.9656C11.3155 16.2505 11.2551 16.5647 11.2745 16.8791C11.2939 17.1936 11.3924 17.498 11.561 17.7641C11.7296 18.0302 11.9627 18.2494 12.2387 18.4013C12.5146 18.5532 12.8245 18.6329 13.1396 18.633C13.1396 18.633 15.5287 18.6007 15.8351 18.6451C15.9651 18.6618 16.0933 18.6902 16.2182 18.7298C16.3461 18.8331 16.4624 18.95 16.565 19.0786L18.3613 21.014C18.4331 21.1111 18.5244 21.1922 18.6293 21.252C18.7342 21.3119 18.8504 21.3492 18.9705 21.3617C19.0906 21.3741 19.212 21.3614 19.3269 21.3243C19.4419 21.2871 19.5478 21.2265 19.638 21.1462C19.7281 21.0658 19.8006 20.9676 19.8507 20.8577C19.9007 20.7478 19.9274 20.6287 19.9288 20.508C19.9303 20.3872 19.9066 20.2675 19.8592 20.1564C19.8119 20.0453 19.7418 19.9453 19.6537 19.8628Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M9.52225 6.48181H8.58274C8.46188 6.48385 8.34641 6.5322 8.26016 6.61689C8.19558 6.68051 8.15144 6.76194 8.13339 6.85078C8.11534 6.93961 8.1242 7.03181 8.15884 7.11559C8.19347 7.19936 8.25231 7.2709 8.32782 7.32105C8.40333 7.37121 8.49209 7.3977 8.58274 7.39713H9.52225C9.64363 7.39713 9.76004 7.34892 9.84587 7.26309C9.9317 7.17726 9.97992 7.06085 9.97992 6.93947C9.97992 6.81809 9.9317 6.70169 9.84587 6.61586C9.76004 6.53003 9.64363 6.48181 9.52225 6.48181Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M18.1837 3.62891C17.3693 3.62855 16.5805 3.91353 15.9543 4.43433C15.3282 4.95513 14.9043 5.67885 14.7563 6.47971H11.847C11.2603 6.47971 11.2583 7.39504 11.847 7.39504H16.0063C16.595 7.39504 16.595 8.30834 16.0063 8.30834H14.1132C13.5265 8.30834 13.5244 9.22367 14.1132 9.22367H15.4236C15.7834 9.69368 16.257 10.0642 16.7998 10.3001C17.3426 10.5361 17.9366 10.6297 18.5257 10.5721C19.1147 10.5145 19.6794 10.3076 20.1662 9.97095C20.653 9.63433 21.0459 9.1791 21.3078 8.64831C21.5696 8.11752 21.6918 7.52871 21.6627 6.93756C21.6336 6.34641 21.4541 5.77246 21.1414 5.26997C20.8286 4.76748 20.3929 4.35306 19.8754 4.06591C19.3578 3.77875 18.7756 3.62834 18.1837 3.62891Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M6.16964 13.6431H5.23012C5.10874 13.6431 4.99233 13.6913 4.90651 13.7771C4.82068 13.8629 4.77246 13.9794 4.77246 14.1007C4.77246 14.2221 4.82068 14.3385 4.90651 14.4244C4.99233 14.5102 5.10874 14.5584 5.23012 14.5584H6.16964C6.2905 14.5564 6.40597 14.508 6.49222 14.4233C6.5568 14.3597 6.60094 14.2783 6.61899 14.1894C6.63703 14.1006 6.62818 14.0084 6.59354 13.9246C6.55891 13.8408 6.50007 13.7693 6.42456 13.7192C6.34904 13.669 6.26029 13.6425 6.16964 13.6431Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
		<path
			d="M9.99231 13.6007C9.83013 13.6019 9.67087 13.5575 9.5327 13.4726C9.39453 13.3877 9.28303 13.2656 9.21092 13.1203C9.13881 12.9751 9.10899 12.8124 9.12488 12.651C9.14077 12.4896 9.20173 12.3359 9.30078 12.2075L8.52054 13.0422C8.43724 13.12 8.37014 13.2135 8.32308 13.3173C8.27602 13.4211 8.24995 13.5331 8.24635 13.647H7.64151C7.0528 13.647 7.05078 14.5624 7.64151 14.5624H9.12941C9.18173 14.5619 9.23358 14.5523 9.28264 14.5341C9.49482 14.4924 9.68345 14.3722 9.81086 14.1974L10.5508 13.391C10.3957 13.5254 10.1975 13.5997 9.99231 13.6007Z"
			fill="#0F2341"
			fill-opacity="0.5"
		/>
	</svg>
</template>
