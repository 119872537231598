import {
	makeGift as make,
	receiveGift as receive,
	getInfo as fetchInfo,
	getStatus as fetchStatus
} from '@/api/api-gifts.js'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/stores'

export const useGiftStore = defineStore('giftStore', () => {
	const authStore = useAuthStore()

	const makeGift = async (variationId) => {
		return await make(variationId, authStore.createAuthHeaders())
	}

	const receiveGift = async (giftCode) => {
		return await receive(giftCode, authStore.createAuthHeaders())
	}

	const getInfo = async (giftCode) => {
		return await fetchInfo(giftCode, authStore.createAuthHeaders())
	}

	const getStatus = async (giftCode) => {
		return await fetchStatus(giftCode, authStore.createAuthHeaders())
	}

	return { makeGift, receiveGift, getInfo, getStatus }
})
