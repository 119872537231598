import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter {
	// static items = {}

	constructor(sources, triggerName = 'ready') {
		super()

		this.sources = sources
		this.items = {}
		this.toLoad = this.sources.length
		this.loaded = 0
		this.loadedAll = false
		this.triggerName = triggerName
		this.setLoaders()
		this.startLoading()
	}

	setLoaders() {
		this.loaders = {}
		this.loaders.gltfLoader = new GLTFLoader()
		this.loaders.objLoader = new OBJLoader()
		this.loaders.textureLoader = new THREE.TextureLoader()
		this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
		this.loaders.RGBELoader = new RGBELoader()
		this.loaders.fontLoader = new FontLoader()
		this.loaders.AudioLoader = new THREE.AudioLoader()
		this.abortController = new AbortController()
		this.signal = this.abortController.signal

		this.loaders.gltfLoader.fetchOptions = {
			signal: this.signal
		}

		this.loaders.textureLoader.fetchOptions = {
			signal: this.signal
		}
	}

	startLoading() {
		// Load each source
		for (const source of this.sources) {
			if (source.path === null) {
				this.sourceLoaded(source, null)
				return
			}

			if (source.type === 'gltfModel') {
				this.loaders.gltfLoader.load(
					source.path,
					(file) => {
						this.sourceLoaded(source, file)
					},
					undefined,
					(error) => {
						if (this.signal.aborted) {
							console.log(`Loading of ${source.name} was aborted`)
						} else {
							console.error(`Error loading ${source.name}:`, error)
						}
					}
				)
			} else if (source.type === 'objModel') {
				this.loaders.objLoader.load(source.path, (file) => {
					this.sourceLoaded(source, file)
				})
			} else if (source.type === 'texture') {
				this.loaders.textureLoader.load(
					source.path,
					(file) => {
						this.sourceLoaded(source, file)
						file.flipY = false
					},
					undefined,
					(error) => {
						if (this.signal.aborted) {
							console.log(`Loading of ${source.name} was aborted`)
						} else {
							console.error(`Error loading ${source.name}:`, error)
						}
					}
				)
			} else if (source.type === 'videoTexture') {
				let videoElement = document.createElement('video')
				videoElement.src = source.path
				videoElement.setAttribute('crossorigin', 'anonymous')
				videoElement.muted = true
				videoElement.loop = true
				videoElement.load()
				videoElement.setAttribute('playsinline', '')
				videoElement.setAttribute('webkit-playsinline', '')
				videoElement.play()

				const obj = {
					videoTexture: new THREE.VideoTexture(videoElement),
					videoElement: videoElement
				}

				videoElement.addEventListener('canplaythrough', () => {
					this.sourceLoaded(source, obj)
				})
			} else if (source.type === 'cubeTexture') {
				this.loaders.cubeTextureLoader.load(source.path, (file) => {
					this.sourceLoaded(source, file)
				})
			} else if (source.type === 'rgbeTexture') {
				this.loaders.RGBELoader.load(
					source.path,
					(file) => {
						this.sourceLoaded(source, file)
					},
					undefined,
					(error) => {
						if (this.signal.aborted) {
							console.log(`Loading of ${source.name} was aborted`)
						} else {
							console.error(`Error loading ${source.name}:`, error)
						}
					}
				)
			} else if (source.type === 'font') {
				this.loaders.fontLoader.load(source.path, (file) => {
					this.sourceLoaded(source, file)
				})
			} else if (source.type === 'audio') {
				this.loaders.AudioLoader.load(source.path, (file) => {
					this.sourceLoaded(source, file)
				})
			}
		}

		if (this.sources.length === 0) {
			setTimeout(() => {
				this.loadedAll = true
				this.trigger(this.triggerName)
			})
		}
	}

	abortLoading() {
		this.abortController.abort()
	}

	sourceLoaded(source, file) {
		this.items[source.name] = file

		this.loaded++

		if (this.loaded === this.toLoad) {
			this.loadedAll = true
			console.log(this.triggerName)
			this.trigger(this.triggerName)
		}
	}
}
