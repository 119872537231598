<template>
	<svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.5 20.5834C14.7467 20.5834 19 16.3301 19 11.0834C19 5.83667 14.7467 1.58337 9.5 1.58337C4.25329 1.58337 0 5.83667 0 11.0834C0 16.3301 4.25329 20.5834 9.5 20.5834Z"
			fill="#D7E6F0"
		/>
		<path
			d="M15.2328 9.74205C15.1356 9.59117 14.9685 9.5 14.789 9.5H11.3396L12.148 0.57541C12.1613 0.427764 12.112 0.281306 12.012 0.171858C11.912 0.0624099 11.7707 0 11.6224 0H9.51131C9.30462 0 9.11699 0.120597 9.03123 0.308618L3.73102 11.9197C3.65641 12.0831 3.67006 12.2732 3.76718 12.4243C3.86429 12.5754 4.03146 12.6667 4.2111 12.6667H7.68883L6.8746 21.5909C6.86114 21.7386 6.91042 21.8852 7.01043 21.9947C7.11045 22.1043 7.25189 22.1667 7.4002 22.1667H9.51131C9.71827 22.1667 9.90609 22.0457 9.99179 21.8573L15.2696 10.2461C15.3438 10.0828 15.33 9.89293 15.2328 9.74205Z"
			fill="#F06423"
		/>
		<path
			d="M13.1218 9.74204C13.0246 9.59117 12.8574 9.49999 12.678 9.49999H9.22855L10.037 0.575401C10.0602 0.319231 9.89534 0.0835784 9.64662 0.0176722C9.3981 -0.0481681 9.13804 0.0746064 9.03123 0.308676L3.73102 11.9198C3.65641 12.0831 3.67006 12.2733 3.76718 12.4243C3.86429 12.5754 4.03153 12.6667 4.21117 12.6667H7.6889L6.87467 21.5909C6.85131 21.8473 7.01624 22.0832 7.26516 22.149C7.31021 22.161 7.3556 22.1667 7.4004 22.1667C7.60313 22.1667 7.79346 22.0492 7.88074 21.8572L13.1585 10.2461C13.2327 10.0828 13.2189 9.89292 13.1218 9.74204Z"
			fill="#FAA019"
		/>
	</svg>
</template>
