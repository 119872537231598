<template>
	<svg class="visually-hidden">
		<clipPath id="curve-large" clipPathUnits="objectBoundingBox">
			<path
				d="M0,0 H0.091 C0.212,0,0.327,0.111,0.401,0.3 L0.54,0.658 C0.624,0.873,0.755,1,0.894,1 H1 H0 V0"
			></path>
		</clipPath>

		<clipPath id="curve-small" clipPathUnits="objectBoundingBox">
			<path
				d="M0,0 H0.062 C0.238,0,0.393,0.184,0.442,0.451 L0.445,0.47 C0.485,0.688,0.597,0.855,0.737,0.905 L1,1 H0.969 H0 V0"
			></path>
		</clipPath>

		<clipPath id="price-button" clipPathUnits="objectBoundingBox">
			<path
				d="M0.1107 1H0.8851C0.8956 1 0.9049 0.9755 0.9049 0.9451V0.0547C0.9049 0.0245 0.8956 0 0.8851 0H0.1107C0.0822 0 0.0569 0.0496 0.0463 0.1264C0.0285 0.2552 0.0013 0.4857 0.0013 0.4857C-0.0005 0.4994 -0.0005 0.5135 0.0013 0.5262C0.0013 0.5262 0.0285 0.7448 0.0463 0.8736C0.0569 0.9504 0.0822 1 0.1107 1Z"
			/>
			<path
				d="M0.8916 1H0.1162C0.1058 1 0.0973 0.9755 0.0973 0.9452V0.0547C0.0973 0.0245 0.1058 0 0.1162 0H0.8916C0.9192 0 0.9444 0.0496 0.9556 0.1264C0.973 0.2552 1 0.4857 1 0.4857C1.0018 0.4994 1.0018 0.5135 1 0.5264C1 0.5264 0.973 0.7448 0.9556 0.8736C0.9444 0.9504 0.9192 1 0.8916 1Z"
			/>
		</clipPath>

		<clipPath id="right-bg" clipPathUnits="objectBoundingBox">
			<path
				d="M0.991,0 L0.991,1 C0.977,0.98,0.961,0.96,0.949,0.939 C0.922,0.92,0.894,0.902,0.867,0.883 C0.754,0.825,0.603,0.784,0.413,0.759 C0.321,0.749,0.228,0.746,0.133,0.748 C0.117,0.746,0.1,0.745,0.083,0.744 C0.389,0.723,0.577,0.661,0.647,0.558 C0.688,0.464,0.598,0.386,0.376,0.327 C0.265,0.299,0.14,0.285,0,0.284 C0.463,0.291,0.78,0.223,0.949,0.08 C0.974,0.054,0.987,0.027,0.991,0"
			/>
		</clipPath>
	</svg>
</template>
