<template>
	<AppModal
		:show="show"
		:customClass="'modal-gift'"
		:custom-style="{ background: bgColor }"
		@close="emit('close')"
	>
		<template #content>
			<h3 v-if="title" class="modal-gift__heading modal-heading font-heading center m-0">
				{{ title }}
			</h3>

			<div class="modal-gift__image">
				<NamedBackground v-if="namedBgSrc" :src="namedBgSrc" />

				<img v-if="image" :src="image" alt="" />
			</div>

			<button class="modal-gift__confirm button button-primary" @click="emit('confirm')">
				{{ confirmText }}
			</button>

			<button class="modal-gift__cancel text-button" @click="emit('close')">Отмена</button>
		</template>
	</AppModal>
</template>

<script setup>
import AppModal from '@/components/AppModal.vue'
import NamedBackground from '@/components/Character/NamedBackground.vue'
import { computed } from 'vue'

const emit = defineEmits(['close', 'confirm'])

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	title: {
		type: String
	},
	image: {
		type: String
	},
	rig: {
		type: Object
	},
	confirmText: {
		type: String,
		default: 'Отправить'
	}
})

const bgColor = computed(() => props.rig?.design_options.bg_gradient)

const namedBgSrc = computed(() => props.rig?.design_options.named_bg_src)
</script>
