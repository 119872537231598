import { getOptions, HOST, request } from './index'

export const getStatus = async (code, authHeaders) => {
	const url = `${HOST}/exchanges/${code}/status`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			return await response.json()
		}
	})
}

export const cancel = async (code, authHeaders) => {
	const url = `${HOST}/exchanges/${code}/cancel`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST'
		})
		if (response.ok) {
			const data = await response.json()

			return data?.status
		}
	})
}

export const connect = async (code, authHeaders) => {
	const url = `${HOST}/exchanges/${code}/connect`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST'
		})
		if (response.ok) {
			return await response.json()
		}
	})
}

export const setSecondCharacter = async (code, characterId, authHeaders) => {
	const url = `${HOST}/exchanges/${code}/set_character`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST',
			body: JSON.stringify({ character_id: characterId })
		})
		if (response.ok) {
			return await response.json()
		}
	})
}

export const create = async (characterId, authHeaders) => {
	const url = `${HOST}/exchanges/create`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST',
			body: JSON.stringify({ character_id: characterId })
		})
		if (response.ok) {
			const data = await response.json()

			return data?.code
		}
	})
}

export const confirm = async (code, authHeaders) => {
	const url = `${HOST}/exchanges/${code}/confirm`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST'
		})
		if (response.ok) {
			return await response.json()
		}
	})
}
