import * as THREE from 'three'
import Experience from '@experience/Experience.js'
import Resources from '@experience/Utils/Resources.js'

export default class Model {
	constructor() {}

	loadModel() {
		if (this[this.sources[0]?.name] || this.loadingModel) {
			return
		}

		this.loadingModel = true

		this.promise = new Promise((res, _) => {
			this.localResources = new Resources(this.sources, this.sourcesReady)

			this.localResources.on(this.sourcesReady, () => {
				// this.addItemsToCache(this.sources, this.localResources.items)
				this.container.clear()
				this.setModel(this.localResources)
				this.setDebug()
				this.loadingModel = false
				res(true)
			})
		})

		return this.promise
	}

	// addItemsToCache(sources, items) {
	// 	if (sources[0]?.path) {
	// 		const foundModel = this.localResourcesCache.models.find(
	// 			(item) => item.source === sources[0].path
	// 		)
	// 		if (!foundModel) {
	// 			this.localResourcesCache.models.push({
	// 				source: sources[0].path,
	// 				model: items[sources[0].name]
	// 			})
	// 		}
	// 	}

	// 	if (sources[1]?.path) {
	// 		const foundTexture = this.localResourcesCache.textures.find(
	// 			(item) => item.source === sources[1].path
	// 		)
	// 		if (!foundTexture) {
	// 			this.localResourcesCache.textures.push({
	// 				source: sources[1].path,
	// 				model: items[sources[1].name]
	// 			})
	// 		}
	// 	}

	// 	if (sources[2]?.path) {
	// 		const foundRoughnessTexture = this.localResourcesCache.roughnessTextures.find(
	// 			(item) => item.source === sources[2].path
	// 		)
	// 		if (!foundRoughnessTexture) {
	// 			this.localResourcesCache.roughnessTextures.push({
	// 				source: sources[2].path,
	// 				model: items[sources[2].name]
	// 			})
	// 		}
	// 	}

	// 	if (sources[3]?.path) {
	// 		const foundNormalTexture = this.localResourcesCache.normalTextures.find(
	// 			(item) => item.source === sources[3].path
	// 		)
	// 		if (!foundNormalTexture) {
	// 			this.localResourcesCache.normalTextures.push({
	// 				source: sources[3].path,
	// 				model: items[sources[3].name]
	// 			})
	// 		}
	// 	}
	// 	console.log(this.localResourcesCache)
	// }

	setModel() {}

	setDebug() {}
}
