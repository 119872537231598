<template>
	<div class="slider">
		<swiper-container
			:injectStyles="swiperStyles"
			ref="swiperRef"
			slides-per-view="1"
			space-between="0"
			speed="500"
			@swiperinit="onInit"
			@swiperslidechange="onSlideChange"
		>
			<swiper-slide v-for="collection in collections" :key="collection.id">
				<div class="collection-card">
					<div class="collection-card__image">
						<img :src="collection.preview_src" class="contain-image" alt="" />

						<NamedBackground v-if="bgSrc" :src="bgSrc" />
					</div>

					<div class="collection-card__name">
						{{ collection.name_ru }}
					</div>
				</div>
			</swiper-slide>
		</swiper-container>
	</div>
</template>

<script setup>
import { computed } from 'vue'

import NamedBackground from '@/components/Character/NamedBackground.vue'

const emit = defineEmits(['init', 'change'])

const props = defineProps({
	collections: {
		type: Array,
		required: true
	},
	lastIndex: {
		type: Number,
		default: 0
	}
})

const swiperStyles = ['.swiper {overflow: visible;}']

const bgSrc = computed(() => {
	return props.collection?.design_options?.named_bg_src
})

function onInit(event) {
	const [swiper] = event.detail

	if (props.lastIndex > 0) {
		swiper.slideTo(props.lastIndex, 0)
	}
}

function onSlideChange(event) {
	const [swiper] = event.detail
	emit('change', props.collections[swiper.activeIndex], swiper.activeIndex)
}
</script>
