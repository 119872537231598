import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getVariation, postVariation, buyCharacterById } from '../api/api-variation'
import { useAuthStore } from './auth-store'
import { useProfileStore } from './profile-store'

export const useVariationsStore = defineStore('variationsStore', () => {
	const isLoading = ref(false)
	const variations = ref({})
	const currentVariation = ref(null)
	const variationsCount = ref(null)
	const currentCharacterId = ref(null)

	const activeMenuCategoryName = ref('')
	const activeCharacterItems = ref(null)

	const authStore = useAuthStore()
	const profileStore = useProfileStore()

	const getRandomNumber = (a, b) => {
		return Math.trunc(Math.random() * (b - a) + a)
	}

	const fetchRandomVariation = async (variationsNumber) => {
		isLoading.value = true

		const randomNumber = getRandomNumber(1, variationsNumber)
		await fetchVariations(randomNumber)
	}

	const fetchCertainVariation = async (characterId, variationNumber) => {
		isLoading.value = true

		const variation = await getVariation(
			authStore.createAuthHeaders(),
			characterId,
			variationNumber
		)
		isLoading.value = false
		return variation
	}

	const fetchVariations = async (currentVariationNum) => {
		const currentVariationNumber =
			currentVariationNum || currentVariation.value.variation_number
		const prevVariationNumber = currentVariationNumber > 1 ? currentVariationNumber - 1 : null
		const nextVariationNumber =
			currentVariationNumber < variationsCount.value ? currentVariationNumber + 1 : null

		if (!variations.value[prevVariationNumber] && prevVariationNumber) {
			const prevFetchedVariation = await fetchCertainVariation(
				currentCharacterId.value,
				prevVariationNumber
			)
			variations.value[prevVariationNumber] = prevFetchedVariation
		}
		if (!variations.value[currentVariationNumber]) {
			const currentFetchedVariation = await fetchCertainVariation(
				currentCharacterId.value,
				currentVariationNumber
			)

			variations.value[currentVariationNumber] = currentFetchedVariation
		}
		if (!variations.value[nextVariationNumber] && nextVariationNumber) {
			const nextFetchedVariation = await fetchCertainVariation(
				currentCharacterId.value,
				nextVariationNumber
			)

			variations.value[nextVariationNumber] = nextFetchedVariation
		}
		currentVariation.value = variations.value[currentVariationNumber]

		return {
			allowSlidePrevComputed: !!prevVariationNumber,
			allowSlideNextComputed: !!nextVariationNumber
		}
	}

	const fetchVariationOnSlideChange = async (direction) => {
		let prevVariationNumber, nextVariationNumber

		if (direction === 'prev') {
			prevVariationNumber =
				currentVariation.value.variation_number - 1 > 1
					? currentVariation.value.variation_number - 2
					: null

			if (!variations.value[prevVariationNumber] && prevVariationNumber) {
				const prevFetchedVariation = await fetchCertainVariation(
					currentCharacterId.value,
					prevVariationNumber
				)

				variations.value[prevVariationNumber] = prevFetchedVariation
			}
			if (currentVariation.value.variation_number > 1) {
				currentVariation.value =
					variations.value[currentVariation.value.variation_number - 1]
			}
			return {
				allowSlidePrevComputed: !!prevVariationNumber,
				allowSlideNextComputed: true
			}
		} else {
			nextVariationNumber =
				currentVariation.value.variation_number + 1 < variationsCount.value
					? currentVariation.value.variation_number + 2
					: null

			if (!variations.value[nextVariationNumber] && nextVariationNumber) {
				const nextFetchedVariation = await fetchCertainVariation(
					currentCharacterId.value,
					nextVariationNumber
				)
				variations.value[nextVariationNumber] = nextFetchedVariation
			}
			if (currentVariation.value.variation_number < variationsCount.value) {
				currentVariation.value =
					variations.value[currentVariation.value.variation_number + 1]
			}

			return {
				allowSlidePrevComputed: true,
				allowSlideNextComputed: !!nextVariationNumber
			}
		}
	}

	const fetchNewVariationItemsChanged = async (variationId, newItem) => {
		isLoading.value = true

		let character = {
			rig_id: variationId,
			accessory_id: activeCharacterItems.value.accessory.id,
			head_id: activeCharacterItems.value.head.id,
			body_id: activeCharacterItems.value.body.id,
			legs_id: activeCharacterItems.value.legs.id,
			feet_id: activeCharacterItems.value.feet.id
		}
		character = { ...character, ...newItem }

		const variation = await postVariation(
			authStore.createAuthHeaders(),
			JSON.stringify({
				character
			})
		)
		await fetchVariations(variation.variation_number)

		isLoading.value = false
	}

	const buyCharacter = async (characterId) => {
		const result = await buyCharacterById(authStore.createAuthHeaders(), characterId)

		if (result && result.message.startsWith('The character is purchased')) {
			await profileStore.fetchCurrency()
			return result
		}
	}

	const toggleFavouriteVariation = (index, isItemFavourite) => {
		if (variations.value[index]) {
			variations.value[index].in_favorites = isItemFavourite
		}
	}

	const setActiveCharacterItems = (items) => {
		activeCharacterItems.value = items
	}

	const setActiveMenuCategoryName = (name) => {
		activeMenuCategoryName.value = name
	}

	const setVariationsCount = (value) => {
		variationsCount.value = value
	}
	const setCurentCharacterId = (value) => {
		currentCharacterId.value = value
	}

	const clearStore = () => {
		variations.value = {}
		currentVariation.value = null
		variationsCount.value = null
		currentCharacterId.value = null
		activeMenuCategoryName.value = ''
		activeCharacterItems.value = null
	}

	return {
		variations,
		currentVariation,
		activeMenuCategoryName,
		fetchVariations,
		fetchVariationOnSlideChange,
		fetchNewVariationItemsChanged,
		fetchRandomVariation,
		fetchCertainVariation,
		buyCharacter,
		toggleFavouriteVariation,
		setActiveCharacterItems,
		setActiveMenuCategoryName,
		setVariationsCount,
		setCurentCharacterId,
		clearStore
	}
})
