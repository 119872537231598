<template>
	<AppModal :show="show" customClass="modal-exchange" @close="emit('close')">
		<template #content>
			<div class="modal-exchange__wrapper">
				<h3 class="modal-exchange__heading modal-heading font-heading center m-0">
					{{ userRole === 'initiator' ? 'Вы предложили обмен' : 'Вам предлагают обмен' }}
				</h3>

				<p v-if="desc" class="modal-exchange__text modal-text center m-0">
					{{ desc }}
				</p>

				<div
					:class="{
						'modal-exchange__exchange-window--rtl': userRole === 'initiator',
						'modal-exchange__exchange-window--with-one-image':
							(userRole === 'initiator' && !receivingCharacter) ||
							(userRole === 'receiving' && waiting)
					}"
					class="modal-exchange__exchange-window"
				>
					<div
						v-if="initiatorCharacter"
						class="modal-exchange__model modal-exchange__model--from"
					>
						<div class="modal-exchange__model-image">
							<img :src="initiatorCharacterImage" class="modal-hello__img" alt="" />
						</div>

						<div class="modal-exchange__model-info">
							<p
								v-if="!(userRole === 'receiving' && waiting)"
								class="modal-exchange__model-info-title"
							>
								{{ userRole === 'initiator' ? 'Ваш герой:' : 'Его герой:' }}
							</p>

							<p class="modal-exchange__model-desc">
								<span class="modal-exchange__model-name">
									{{ initiatorCharacter.rig.name_ru }}
								</span>
								|
								<span class="modal-exchange__model-id">
									#{{
										String(initiatorCharacter.variation_number).padStart(5, '0')
									}}
								</span>
							</p>
						</div>
					</div>

					<div class="modal-exchange__icon">
						<IconExchange />
					</div>

					<div class="modal-exchange__model modal-exchange__model--to">
						<div class="modal-exchange__model-image" @click="emit('confirm')">
							<img
								v-if="userRole === 'initiator' || receivingCharacterImage"
								:src="receivingCharacterImage || 'images/character-blurred.png'"
								class="modal-hello__img"
								alt=""
							/>

							<IconUnknown v-else />
						</div>

						<div class="modal-exchange__model-info">
							<p
								v-if="
									!(userRole === 'receiving' && waiting) &&
									(userRole === 'receiving' || receivingCharacter)
								"
								class="modal-exchange__model-info-title"
							>
								{{ userRole === 'initiator' ? 'Его герой:' : 'Ваш герой:' }}
							</p>

							<p class="modal-exchange__model-desc">
								<span class="modal-exchange__model-name">
									{{ receivingCharacter?.rig?.name_ru || '?' }}
								</span>

								<template
									v-if="
										!(userRole === 'receiving' && waiting) && receivingCharacter
									"
								>
									|
									<span class="modal-exchange__model-id">
										#{{
											String(receivingCharacter.variation_number).padStart(
												5,
												'0'
											)
										}}
									</span>
								</template>
							</p>
						</div>
					</div>
				</div>

				<button
					v-if="buttonText"
					class="modal-exchange__confirm button button-primary"
					@click="emit('confirm')"
				>
					{{ buttonText }}
				</button>

				<div v-else class="modal-exchange__wait-respond button">
					<span class="text">Ожидаем ответ</span>
				</div>

				<button class="modal-exchange__cancel text-button" @click="emit('close')">
					Отменить
				</button>
			</div>
		</template>
	</AppModal>
</template>

<script setup>
import { computed } from 'vue'

import AppModal from '@/components/AppModal.vue'
import IconExchange from '@/components/Icons/IconExchange.vue'
import IconUnknown from '@/components/Icons/IconUnknown.vue'

const emit = defineEmits(['close', 'confirm'])

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	userRole: {
		type: String,
		required: true,
		validator(value) {
			return ['initiator', 'receiving'].includes(value)
		}
	},
	initiatorCharacter: {
		type: Object
	},
	initiatorCharacterImage: {
		type: String
	},
	receivingCharacter: {
		type: Object
	},
	receivingCharacterImage: {
		type: String
	},
	waiting: {
		type: Boolean,
		default: false
	}
})

const desc = computed(() => {
	if (props.userRole === 'initiator' && props.receivingCharacter) {
		return 'Подтвердите условия обмена, если согласны с его условиями'
	} else if (props.userRole === 'receiving') {
		return 'Выберите персонажа из своих коллекций, на которого вы хотите обменяться'
	} else {
		return ''
	}
})

const buttonText = computed(() => {
	if (props.userRole === 'receiving' && props.waiting) {
		return ''
	} else if (props.userRole === 'initiator' && props.receivingCharacter) {
		return 'Подтвердить'
	} else if (props.userRole === 'receiving' && !props.receivingCharacter) {
		return 'Выбрать'
	} else if (props.userRole === 'receiving' && props.receivingCharacter) {
		return 'Предложить'
	} else {
		return ''
	}
})
</script>
