import EventEmitter from './EventEmitter.js'
import Experience from '@experience/Experience.js'

export default class Sizes extends EventEmitter {
	static _instance = null
	experience = Experience.getInstance()
	canvas = this.experience.canvas

	static getInstance() {
		return Sizes._instance || new Sizes()
	}

	constructor() {
		// Singleton
		if (Sizes._instance) {
			return Sizes._instance
		}

		super()

		Sizes._instance = this

		// Setup

		this.pixelRatio = Math.min(window.devicePixelRatio, 2)
		this.width = this.canvas.clientWidth
		this.height = this.canvas.clientHeight

		this.width_DPR = this.width * window.devicePixelRatio
		this.height_DPR = this.height * window.devicePixelRatio

		// Resize event
		window.addEventListener('resize', () => {
			this.pixelRatio = Math.min(window.devicePixelRatio, 2)
			this.width = this.canvas.clientWidth
			this.height = this.canvas.clientHeight

			this.width_DPR = this.width * window.devicePixelRatio
			this.height_DPR = this.height * window.devicePixelRatio

			this.trigger('resize')
		})
	}
}
