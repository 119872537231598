import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useHeaderStore = defineStore('headerStore', () => {
	const isShow = ref(true)

	const hide = () => {
		isShow.value = false
	}

	const show = () => {
		isShow.value = true
	}

	return {
		isShow,
		hide,
		show
	}
})
