<template>
	<div class="login-form__container">
		<slot name="heading"></slot>

		<div v-if="step === 'first'" class="login-form__first-step">
			<slot name="first-step-content"></slot>

			<CountryPhone
				:class="{ 'country-phone--error': showErrorMessage }"
				v-model="phoneNumber"
				@validation="handlePhoneValidation"
			/>

			<div
				class="login-form__error"
				:class="{ 'login-form__error--visible': showErrorMessage }"
			>
				{{ errorMessage }}
			</div>

			<button
				type="button"
				class="login-form__button button button-primary"
				@click="handleNextStepButtonClick"
			>
				Выслать код
			</button>
		</div>

		<div v-if="step === 'signUp'" class="login-form__second-step">
			<slot name="sign-up-step-content"></slot>

			<VOtpInput
				class="login-form__code-input-wrapper"
				inputmode="numeric"
				:input-classes="[
					'login-form__code-input',
					{ 'login-form__code-input--error': showErrorMessage }
				]"
				:num-inputs="6"
				v-model:value="code"
			/>

			<div
				class="login-form__error"
				:class="{ 'login-form__error--visible': showErrorMessage }"
			>
				{{ errorMessage }}
			</div>

			<button
				type="button"
				class="login-form__button-repeat-code center"
				:class="{ 'login-form__button-repeat-code--disabled': !canSendAgain }"
				@click="handleRepeatCodeButtonClick"
			>
				Запросить код повторно
				<span v-if="!canSendAgain" v-html="remainingTimeMessage"></span>
			</button>

			<button
				type="button"
				class="login-form__button button button-primary"
				@click="handleSignUp"
			>
				Зарегистрироваться
			</button>
		</div>

		<div v-if="step === 'signIn'" class="login-form__second-step">
			<slot name="sign-in-step-content"></slot>

			<VOtpInput
				class="login-form__code-input-wrapper"
				inputmode="numeric"
				:input-classes="[
					'login-form__code-input',
					{ 'login-form__code-input--error': showErrorMessage }
				]"
				:num-inputs="6"
				v-model:value="code"
			/>

			<div
				class="login-form__error"
				:class="{ 'login-form__error--visible': showErrorMessage }"
			>
				{{ errorMessage }}
			</div>

			<button
				type="button"
				class="login-form__button-repeat-code center"
				:class="{ 'login-form__button-repeat-code--disabled': !canSendAgain }"
				@click="handleSendPasswordButtonClick"
			>
				Отправить пароль через SMS
				<span v-if="!canSendAgain" v-html="remainingTimeMessage"></span>
			</button>

			<button
				type="button"
				class="login-form__button button button-primary"
				@click="handleSignIn"
			>
				Войти
			</button>
		</div>
	</div>

	<Teleport to="body">
		<FullScreenPreloader :loading="authStore.isLoading" />
		<ErrorModal :show="showErrorModal" @close="showErrorModal = false" />
	</Teleport>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'
import CountryPhone from '@/components/Login/CountryPhone.vue'
import VOtpInput from 'vue3-otp-input'
import { useAuthStore } from '@/stores'
import FullScreenPreloader from '@/components/Preloaders/FullScreenPreloader.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'

const phoneNumber = ref('')
const code = ref('')
const showErrorModal = ref(false)
const showErrorMessage = ref(false)
const errorMessage = ref('')

const canSendAgain = ref(false)
const sendAgainRemainingTime = ref(0)
let sendAgainTimer

const step = ref('first')
const phoneNumberValid = ref(false)

const authStore = useAuthStore()

const emit = defineEmits(['changeStep'])

const remainingTimeMessage = computed(() => {
	return sendAgainRemainingTime.value < 3600
		? `через ${sendAgainRemainingTime.value}&nbsp;сек`
		: `через ${Math.floor(sendAgainRemainingTime.value / 3600)}&nbsp;ч`
})

const handlePhoneValidation = ({ validation }) => {
	if (validation === 'success') {
		phoneNumberValid.value = true
		hideError()
	} else {
		showError('Неверно введен номер')
		phoneNumberValid.value = false
	}
}

const handleNextStepButtonClick = async () => {
	if (!phoneNumberValid.value) {
		showError('Неверно введен номер')
		return
	} else {
		hideError()
	}

	const result = await authStore.sendCode(phoneNumber.value)

	if (result.wait_seconds) {
		setTimer(result.wait_seconds)
	}

	if (!result) {
		showErrorModal.value = true
		return
	}

	if (result.method === 'sign-in') {
		step.value = 'signIn'
		canSendAgain.value = true

		if (result.wait_seconds) {
			setTimer(result.wait_seconds)
		}

		return
	}

	step.value = 'signUp'
}

const handleSignUp = async () => {
	if (codeIsInvalid()) {
		showError('Неверно введен код')
		return
	}

	hideError()

	const result = await authStore.signUp(phoneNumber.value, code.value)

	if (!result) {
		showError('Неверный код')
	}
}

const handleSignIn = async () => {
	if (codeIsInvalid()) {
		showError('Неверно введен код')
		return
	}

	hideError()

	const result = await authStore.signIn(phoneNumber.value, code.value)

	if (!result) {
		showError('Неверный пароль')
	}
}

const handleRepeatCodeButtonClick = async () => {
	if (!canSendAgain.value) {
		return
	}

	const result = await authStore.sendCodeAgain(phoneNumber.value)

	if (result.wait_seconds) {
		setTimer(result.wait_seconds)
	}

	if (!result) {
		showErrorModal.value = true
		return
	}
}

const handleSendPasswordButtonClick = async () => {
	if (!canSendAgain.value) {
		return
	}

	const result = await authStore.sendPassword(phoneNumber.value)

	if (result.wait_seconds) {
		setTimer(result.wait_seconds)
	}

	if (!result) {
		showErrorModal.value = true
		return
	}
}

watchEffect(() => {
	emit('changeStep', step.value)
})

function showError(message) {
	errorMessage.value = message
	showErrorMessage.value = true
}

function hideError() {
	errorMessage.value = ''
	showErrorMessage.value = false
}

function codeIsInvalid() {
	return code.value.length !== 6
}

function setTimer(seconds) {
	clearTimer()
	canSendAgain.value = false

	sendAgainRemainingTime.value = seconds

	sendAgainTimer = setInterval(() => {
		if (sendAgainRemainingTime.value === 0) {
			clearTimer()
			canSendAgain.value = true
			return
		}

		sendAgainRemainingTime.value -= 1
	}, 1000)
}

function clearTimer() {
	clearInterval(sendAgainTimer)
}
</script>
