import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from '../State.js'
import Materials from '../Materials/Materials.js'
import Rig from './Rig.js'
import Accessory from './Accessory.js'
import Head from './Head.js'
import Body from './Body.js'
import Legs from './Legs.js'
import Feet from './Feet.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'

export default class Character extends Model {
	experience = Experience.getInstance()
	debug = Debug.getInstance()
	state = State.getInstance()
	materials = Materials.getInstance()
	raycaster = new THREE.Raycaster()
	mouse = new THREE.Vector2()

	container = new THREE.Group()

	constructor() {
		super()

		this.scene = this.experience.scene
		this.canvas = this.experience.canvas
		this.time = this.experience.time
		this.camera = this.experience.camera
		this.cameraClass = this.experience.camera
		this.renderer = this.experience.renderer
		this.resources = this.experience.resources
		this.html = this.experience.html

		this.sharedSkeleton = null
		this.rig = new Rig(this.resources)
		this.accessory = new Accessory(this.resources)
		this.head = new Head(this.resources)
		this.body = new Body(this.resources)
		this.legs = new Legs(this.resources)
		this.feet = new Feet(this.resources)
		// this.container.position.y = -1.3
		this.container.isRotated = false

		this.defaultObjectConfig = {
			parent: this.scene,
			transformation: {
				position: new THREE.Vector3(0, -1.3, 0),
				rotation: new THREE.Euler(0, 0, 0),
				scale: new THREE.Vector3(1, 1, 1)
			}
		}

		this.changeTransformationObject(this.defaultObjectConfig.transformation)

		this.setUI()
		this.setDebug()
		this.setListeners()
	}

	loadModels(modelsSrc) {
		this.time.togglePlaying(false)
		this.promissesArr = []
		this.scene.remove(this.container)

		modelsSrc.forEach((modelSrc) => {
			this[modelSrc.categoryName].clear()

			modelSrc.model.forEach((src) => {
				if (src.path) {
					this[modelSrc.categoryName].sources.push(src)
				}
			})
			if (modelSrc.model[0].path) {
				this.promissesArr.push(this[modelSrc.categoryName].loadModel())
			}
		})

		Promise.all(this.promissesArr).then(() => {
			this.sharedSkeleton = this.rig.sharedSkeleton
			this.setSharedSkeleton()
			modelsSrc.forEach((modelSrc) => {
				this.container.add(this[modelSrc.categoryName].container)
			})
			this.scene.add(this.container)
			this.setAnimations()
			this.canvas.dispatchEvent(new CustomEvent('hide-preloader'))
			this.time.togglePlaying(true)
		})
	}

	loadModel(categoryName, modelSrc) {
		this.time.togglePlaying(false)
		this[categoryName].clear()
		this[categoryName].sources.push(...modelSrc)

		this[categoryName].loadModel().then(() => {
			this[categoryName].setSharedBones(this.sharedSkeleton)
			this.time.togglePlaying(true)
			this.canvas.dispatchEvent(new CustomEvent('hide-preloader'))
		})
	}

	setSharedSkeleton() {
		this.head.setSharedBones(this.sharedSkeleton)
		this.accessory.setSharedBones(this.sharedSkeleton)
		this.body.setSharedBones(this.sharedSkeleton)
		this.legs.setSharedBones(this.sharedSkeleton)
		this.feet.setSharedBones(this.sharedSkeleton)
	}

	setModel() {
		// resource
	}

	translateModel(isModelTranslated) {
		const targetPositionX = isModelTranslated
			? this.container.position.x + 0.3
			: this.container.position.x - 0.3

		const startPositionX = this.container.position.x

		const duration = 300
		const startTime = performance.now()

		const animate = (time) => {
			const elapsedTime = time - startTime
			const progress = Math.min(elapsedTime / duration, 1)

			this.container.position.x = THREE.MathUtils.lerp(
				startPositionX,
				targetPositionX,
				progress
			)

			if (progress < 1) {
				requestAnimationFrame(animate)
			}
		}

		requestAnimationFrame(animate)
	}

	changeTransformationObject({ position, rotation, scale }) {
		if (position) this.container.position.copy(position)
		if (rotation) this.container.rotation.copy(rotation)
		if (scale) this.container.scale.copy(scale)
	}

	reattachParent({ parent, transformation } = this.defaultObjectConfig) {
		console.log(parent, transformation, 'parent, transformation @reattachParent')

		if (this.container) {
			if (parent) {
				parent.attach(this.container)
			}

			this.changeTransformationObject(transformation)
		} else {
			console.error('this.container is not defined @reattachParent')
		}
	}

	setCamera() {
		const box = new THREE.Box3().setFromObject(this.container)
		const centerBox = box.getCenter(new THREE.Vector3(0, 1.5, 0))
		this.camera.instance.position.setX(centerBox.x)
		this.camera.instance.position.setY(centerBox.y)
		this.cameraClass.controls.target = centerBox
	}

	setAnimations() {
		this.animation = {}

		// Mixer
		this.animation.mixer = new THREE.AnimationMixer(this.rig.sharedParentBone)

		// Actions
		this.animation.actions = {}

		this.rig.source.animations.forEach((animation) => {
			this.animation.actions[animation.name] = this.animation.mixer.clipAction(animation)
		})

		this.animation.actions.current = this.animation.actions.idle
		this.animation.actions.current?.play()

		// Play the action
		this.animation.play = (name) => {
			const newAction = this.animation.actions[name]
			const oldAction = this.animation.actions.current

			if (newAction && oldAction && newAction !== oldAction) {
				newAction.reset()
				newAction.play()
				newAction.crossFadeFrom(oldAction, 1)

				this.animation.actions.current = newAction
			}
		}
	}

	startAnimation(animationName) {
		this.animation.play(animationName)
	}

	stopAnimation() {
		this.animation.play('idle')
	}

	toggleRotation(value) {
		this.container.isRotated = value
		this.container.rotation.y = value ? -1 : 0
	}

	resetRotation() {
		const duration = 0.5
		const startTime = performance.now()
		const startRotationY = this.container.rotation.y

		const animate = () => {
			const elapsedTime = (performance.now() - startTime) / 1000
			const t = Math.min(elapsedTime / duration, 1)

			this.container.rotation.y = THREE.MathUtils.lerp(startRotationY, 0, t)

			if (t < 1) {
				requestAnimationFrame(animate)
			} else {
				this.container.rotation.y = 0
			}
		}

		animate()
	}

	setListeners() {
		this.canvas.addEventListener('click', this.onClick.bind(this))
	}

	onClick(event) {
		this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1
		this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
		this.raycaster.setFromCamera(this.mouse, this.camera.instance)
		const intersects = this.raycaster.intersectObjects(this.container.children, true)
		if (intersects.length > 0) {
			window.dispatchEvent(new CustomEvent('model-click'))
		}
	}

	setUI() {}

	abortLoadingLocalResources() {
		this.rig?.localResources?.abortLoading()
		this.head?.localResources?.abortLoading()
		this.accessory?.localResources?.abortLoading()
		this.body?.localResources?.abortLoading()
		this.legs?.localResources?.abortLoading()
		this.feet?.localResources?.abortLoading()
	}

	resize() {}

	setDebug() {
		if (!this.debug.active) return

		//this.debug.createDebugTexture( this.resources.items.displacementTexture )
	}

	update(deltaTime) {
		this.animation && this.animation.mixer.update(this.time.delta)

		if (this.container.isRotated) {
			this.container.rotation.y += deltaTime * 0.3
		}
	}
}
