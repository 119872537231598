<template>
	<Transition name="modal">
		<div
			v-if="show"
			class="modal-energy"
			v-click-outside="{ handler: emitClose, exclude: props.excludeRef }"
		>
			<h3 class="modal-energy__h3">Зарабатывай энергию!</h3>
			<div class="modal-energy__description">
				Нажимай на персонажа, зарабатывай энергию, и покупай анимацию.
			</div>
			<RouterLink to="/?tab=character" class="modal-energy__link" @click="emitClose">
				Мои герои
			</RouterLink>
		</div>
	</Transition>
</template>

<script setup>
import { RouterLink } from 'vue-router'
const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	excludeRef: {
		type: [Object, null],
		required: true
	}
})

const emit = defineEmits(['close'])

const emitClose = () => {
	emit('close')
}
</script>
