export const HOST = import.meta.env.VITE_API_URL

export const OPTIONS = {
	method: 'GET',
	headers: {
		'Content-Type': 'application/json'
	}
}

const { fetch: originalFetch } = window

window.fetch = async (...args) => {
	try {
		let [resource, config] = args

		let response = await originalFetch(resource, config)

		const token = localStorage.getItem('access_token')

		if (response.status === 401 && token) {
			localStorage.removeItem('access_token')
			window.location.reload()
		}

		return response
	} catch {
		return
	}
}

export async function request(func) {
	try {
		const result = await func()
		return result
	} catch (err) {
		console.error(err)
	}
}

export function getOptions(authHeaders) {
	return {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			...authHeaders
		}
	}
}
