import { getOptions, HOST, request } from './index'

export const getRigs = async (authHeaders) => {
	const url = `${HOST}/collections/rigs`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			const data = await response.json()

			return data?.rigs || []
		}
	})
}

export const getRigVariationsById = async (authHeaders, id) => {
	const url = `${HOST}/collections/rigs/${id}/characters`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			let data = (await response.json()) || []

			return prepareVariationsData(data)
		}
	})
}

export const getRigCharactersIds = async (authHeaders, rigId) => {
	const url = `${HOST}/collections/rigs/${rigId}/characters_ids`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			return await response.json()
		}
	})
}

export const getVariationsArray = async (authHeaders, rigId) => {
	const url = `${HOST}/collections/rigs/${rigId}/variations`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			return await response.json()
		}
	})
}

function prepareVariationsData(data) {
	return data.map((item) => {
		const result = JSON.parse(JSON.stringify(item))

		result.items.current = result.items

		return result
	})
}
