<template>
	<div class="tabs">
		<div class="tabs__navigation">
			<button
				v-for="tab in tabs"
				:key="tab.name"
				:class="{ active: tab.name === activeTab }"
				class="tabs__navigation-button font-heading uppercase"
				@click="handleChangeActiveTab(tab.name)"
			>
				<slot :name="`${tab.name}-button`"></slot>
				<span class="tabs__navigation-button-bg">
					<span class="tabs__navigation-button-bg-self"></span>
					<span class="tabs__navigation-button-underbutton"></span>
				</span>
			</button>
		</div>
		<div class="tabs__content">
			<div v-for="tab in tabs" :key="tab.name" class="tabs__tab-wrapper" style="height: 100%">
				<slot :name="tab.name" v-if="tab.name === activeTab"></slot>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
	tabs: {
		type: Array,
		required: true,
		validator: (value) => value.every((tab) => 'name' in tab)
	}
})

const route = useRoute()
const router = useRouter()

const activeTab = ref(route.query.tab || props.tabs[0].name)

const handleChangeActiveTab = (name) => {
	activeTab.value = name
	router.push({
		name: 'Home',
		query: {
			tab: activeTab.value
		}
	})
}
</script>
