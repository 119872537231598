<template>
	<AppModal
		:show="show"
		:customClass="'modal-select-collection'"
		:customStyle="{ backgroundImage: bgColor }"
		@close="emit('close')"
	>
		<template #content>
			<div class="modal-select-collection__exchange-step">
				<h3 class="modal-select-collection__heading modal-heading font-heading center m-0">
					Выберите коллекцию
				</h3>

				<div class="modal-select-collection__collections">
					<ChooseCollectionSlider
						v-if="collections && collections.length"
						:last-index="lastIndex"
						:collections="collections"
						@change="onChange"
					/>

					<p v-else class="modal-select-collection__empty-text modal-text center m-0">
						У вас нет ни одного персонажа
					</p>
				</div>

				<div class="modal-select-collection__actions">
					<button
						class="modal-select-collection__button button button-primary"
						@click="emit('confirm')"
					>
						Далее
					</button>

					<button
						class="text-button modal-select-collection__button-cancel"
						@click="emit('close')"
					>
						Отменить
					</button>
				</div>
			</div>
		</template>
	</AppModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useCharactersStore } from '@/stores/characters-store'

import AppModal from '@/components/AppModal.vue'
import ChooseCollectionSlider from '@/components/Sliders/ChooseCollectionSlider.vue'

const emit = defineEmits(['cancel', 'confirm', 'update:currentCollection'])

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	collections: {
		type: Array
	},
	currentCollection: {
		type: Object
	}
})

const charactersStore = useCharactersStore()
const lastIndex = ref(0)

const bgColor = computed(() => {
	if (props.currentCollection) {
		return props.currentCollection.design_options.bg_gradient || false
	}
	return false
})

watch(
	() => props.collections,
	(newValue) => {
		if (newValue && newValue.length) {
			emit('update:currentCollection', newValue[0])
		}
	}
)

function onChange(collection, index) {
	lastIndex.value = index
	emit('update:currentCollection', collection)
}
</script>
