import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from '../State.js'
import Materials from '../Materials/Materials.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'

export default class Rig extends Model {
	experience = Experience.getInstance()
	debug = Debug.getInstance()
	state = State.getInstance()
	materials = Materials.getInstance()
	scene = experience.scene
	time = experience.time
	camera = experience.camera.instance
	cameraClass = experience.camera
	renderer = experience.renderer.instance
	resources = experience.resources
	container = new THREE.Group()

	constructor() {
		super()

		this.sources = []
		this.localResourcesCache = {
			models: [],
			textures: [],
			roughnessTextures: [],
			normalTextures: []
		}
		this.sourcesReady = 'rigReady'
		this.setDebug()
	}

	setModel(localResources) {
		this.source = localResources.items.rigModel
		this.model = this.source.scene

		this.sharedModel = SkeletonUtils.clone(this.model)
		this.shareSkinnedMesh = {}

		this.sharedModel.traverse((object) => {
			if (object instanceof THREE.SkinnedMesh) {
				this.shareSkinnedMesh = object
			}
		})
		this.sharedSkeleton = this.shareSkinnedMesh.skeleton
		this.sharedParentBone = this.sharedModel.getObjectByName('mixamorigHips')
		this.container.name = 'Rig'
		this.container.add(this.sharedModel)
	}

	clear() {
		this.sources = []
		this.rigModel = null
	}

	resize() {}

	// setDebug() {
	// 	if (!this.debug.active) return
	// }

	update(deltaTime) {}
}
