<template>
	<header v-if="headerStore.isShow" class="header">
		<div class="header__wrapper">
			<div class="header__left">
				<p class="header__menu-logo">LOGO</p>
				<!-- <button type="button" class="header__menu-button">
					<span class="icon">
						<IconMenu />
					</span>
				</button>
				<button type="button" class="header__notification-button">
					<span class="icon">
						<IconNotification />
					</span>
					<span class="header__notification-button-circle"></span>
				</button> -->
			</div>

			<div v-if="store.currency" class="header__right">
				<button
					type="button"
					class="header__energy-button"
					ref="excludeElement"
					@click="showEnergyModal = !showEnergyModal"
				>
					<span class="icon"><IconEnergy /></span>
					<span>{{ store.currency?.energy }}</span>
				</button>

				<Teleport to="body">
					<EnergyModal
						:show="showEnergyModal"
						:excludeRef="excludeElement"
						@close="showEnergyModal = false"
					/>
				</Teleport>

				<div class="header__money-amount">
					<img src="/pechenka.png" alt="pechenka" />
					<span>{{ store.currency?.coins }}</span>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useHeaderStore, useProfileStore } from '../stores/index'
import IconNotification from '@/components/Icons/IconNotification.vue'
import IconMenu from '@/components/Icons/IconMenu.vue'
import IconEnergy from '@/components/Icons/IconEnergy.vue'
import EnergyModal from '@/components/Modals/EnergyModal.vue'

const store = useProfileStore()
const headerStore = useHeaderStore()

onMounted(() => {
	store.fetchCurrency()
})

const showEnergyModal = ref(false)
const excludeElement = ref(null)
</script>
