import './assets/styles/index.scss'
import { clickOutsideDirective } from '../src/helpers/directives'
import '@/helpers/lock-rotate'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import { register } from 'swiper/element/bundle'
register()

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.directive('click-outside', clickOutsideDirective)

import '@/aframe-components/tap-to-place.js'

app.mount('#app')
