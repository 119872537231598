<template>
	<div class="card-stack__card card" ref="cardElement" @click="emitClick">
		<div
			class="card__content"
			:style="{
				'--gradient': bgColor
			}"
		>
			<h3 class="card__name font-heading m-0 uppercase">
				{{ card.name_ru }}
			</h3>

			<div class="card__image">
				<img :src="card.preview_src" :alt="card.name_ru" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	index: {
		index: Number,
		required: true
	},
	card: {
		type: Object,
		required: true
	}
})

const bgColor = computed(() => props.card.design_options.bg_gradient)

const emit = defineEmits(['clickOnCard'])

const emitClick = () => {
	emit('clickOnCard', props.card)
}
</script>
