import { getRigs } from '@/api/api-collections.js'
import { computed, onMounted } from 'vue'
import { useAuthStore, useHeroesStore } from '@/stores/index.js'

export default function useUserHeroes() {
	const heroesStore = useHeroesStore()
	const heroes = computed(() => heroesStore.heroes)

	const authStore = useAuthStore()

	async function getUserHeroes() {
		const data = await getRigs(authStore.createAuthHeaders())
		heroesStore.setHeroes(data)
	}

	onMounted(() => {
		getUserHeroes()
	})

	return { heroes }
}
