import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from '../State.js'
import Materials from '../Materials/Materials.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'
import Resources from '../Utils/Resources.js'

export default class Head extends Model {
	experience = Experience.getInstance()
	debug = Debug.getInstance()
	state = State.getInstance()
	materials = Materials.getInstance()
	scene = experience.scene
	time = experience.time
	camera = experience.camera.instance
	cameraClass = experience.camera
	renderer = experience.renderer.instance
	resources = experience.resources
	container = new THREE.Group()

	constructor() {
		super()

		this.sharedSkeleton = null
		this.sources = []
		this.localResourcesCache = {
			models: [],
			textures: [],
			roughnessTextures: [],
			normalTextures: []
		}
		this.sourcesReady = 'headReady'
		this.setDebug()
	}

	setModel(localResources) {
		this.sourceHead = localResources.items.headModel
		this.sourceHeadTexture = localResources.items.headTexture

		if (this.sourceHeadTexture) {
			this.sourceHeadTexture.colorSpace = THREE.SRGBColorSpace
		}

		this.sourceHeadRoughnessTexture = localResources.items.headRoughnessTexture
		this.sourceHeadNormalTexture = localResources.items.headNormalTexture
		this.sourceHeadAlphaMap = localResources.items.headAlphaMap

		this.headModel = this.sourceHead.scene
		this.headModel.bindMode = THREE.DetachedBindMode
		this.headModel.traverse((child) => {
			if (child.isMesh) {
				child.material.map = this.sourceHeadTexture
				child.material.roughness = 1
				child.material.roughnessMap = this.sourceHeadRoughnessTexture
				child.material.normalMap = this.sourceHeadNormalTexture
				child.material.transparent = true
				child.material.alphaMap = this.sourceHeadAlphaMap
				child.material.needsUpdate = true
			}
		})

		this.container.name = 'Head'
		this.container.add(this.headModel)
	}

	resize() {}

	// setDebug() {
	// 	if (!this.debug.active) return
	// }

	setSharedBones(sharedSkeleton) {
		this.headModel?.traverse((object) => {
			if (object instanceof THREE.SkinnedMesh) {
				object.bind(sharedSkeleton, new THREE.Matrix4())
			}
		})
	}

	clear() {
		this.sources = []
		this.headModel = null
	}

	update(deltaTime) {}
}
