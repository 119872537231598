<template>
	<svg viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M11.9584 1.23734C12.3511 0.833484 12.9996 0.833484 13.3923 1.23734L14.3222 2.19371C14.6996 2.58184 14.6996 3.19979 14.3222 3.58792L5.34541 12.8204C4.96804 13.2086 4.96804 13.8265 5.34541 14.2146L14.1995 23.3209C14.5769 23.7091 14.5769 24.327 14.1995 24.7151L13.2696 25.6715C12.8769 26.0754 12.2283 26.0754 11.8357 25.6715L0.677365 14.1953C0.299987 13.8072 0.299988 13.1893 0.677365 12.8011L2.31483 11.117C2.31996 11.1117 2.32845 11.1117 2.33359 11.117C2.33873 11.1223 2.34722 11.1223 2.35236 11.117L11.9584 1.23734Z"
			fill="currentColor"
		/>
	</svg>
</template>
