<template>
	<div class="modal-slider">
		<swiper-container
			ref="slider"
			slides-per-view="auto"
			space-between="20"
			speed="500"
			:allow-touch-move="showNavigation"
			:loop="true"
			:navigation="showNavigation && navElements"
			@swiperslidechange="slideChange"
			@swiperslidechangetransitionend="slideChangeTransitionEnd"
		>
			<swiper-slide
				v-for="item in numberOfSlides"
				:key="item"
				class="constructor-view__bg-slide"
				:style="{
					backgroundImage: bgColor
				}"
			>
				<NamedBackground v-if="namedBgSrc" :src="namedBgSrc" />
			</swiper-slide>
		</swiper-container>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import NamedBackground from '@/components/Character/NamedBackground.vue'

const emit = defineEmits(['prev', 'next', 'transition-end'])

const props = defineProps({
	rig: {
		type: Object,
		required: true
	},
	showNavigation: {
		type: Boolean
	},
	navElements: {
		type: Object
	}
})

const numberOfSlides = 3
const slideIndex = ref(0)

const bgColor = computed(() => props.rig?.design_options.bg_gradient)

const namedBgSrc = computed(() => props.rig?.design_options.named_bg_src)

watch(slideIndex, (newValue, oldValue) => {
	if (oldValue === 0 && newValue === numberOfSlides - 1) {
		emit('prev')
	} else if (oldValue === numberOfSlides - 1 && newValue === 0) {
		emit('next')
	} else if (newValue < oldValue) {
		emit('prev')
	} else {
		emit('next')
	}
})

function slideChange(event) {
	const [swiper] = event.detail
	slideIndex.value = swiper.realIndex
}

function slideChangeTransitionEnd() {
	emit('transition-end')
}
</script>
