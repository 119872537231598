<template>
	<AppModal :show="show" @close="emit('close')" :customClass="'modal-error'">
		<template #content>
			<h3 class="modal-error__heading modal-heading font-heading center m-0">Упс :(</h3>

			<p v-html="resultText" class="modal-error__text modal-text center m-0"></p>

			<button class="modal-error__button button button-primary" @click="emit('close')">
				Ясно
			</button>
		</template>
	</AppModal>
</template>

<script setup>
import { computed } from 'vue'
import AppModal from '@/components/AppModal.vue'

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	text: {
		type: String,
		default: 'Что-то пошло не так.<br />Попробуйте еще раз'
	}
})

const emit = defineEmits(['close'])

const resultText = computed(() => {
	return props.text || 'Что-то пошло не так.<br />Попробуйте еще раз'
})
</script>
