import { getOptions, HOST, request } from './index'

export const getCharacter = async (authHeaders, code) => {
	const url = `${HOST}/qr/get`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST',
			body: JSON.stringify({ code: code })
		})

		const data = await response.json()

		if (response.ok || data.status) {
			return data
		}
	})
}

export const saveCharacter = async (authHeaders, code) => {
	const url = `${HOST}/qr/save`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST',
			body: JSON.stringify({ code: code })
		})

		return response.ok
	})
}
