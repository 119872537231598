import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from '../State.js'
import Materials from '../Materials/Materials.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'
import Resources from '../Utils/Resources.js'

export default class Feet extends Model {
	experience = Experience.getInstance()
	debug = Debug.getInstance()
	state = State.getInstance()
	materials = Materials.getInstance()
	scene = experience.scene
	time = experience.time
	camera = experience.camera.instance
	cameraClass = experience.camera
	renderer = experience.renderer.instance
	resources = experience.resources
	container = new THREE.Group()

	constructor() {
		super()

		this.sources = []
		this.localResourcesCache = {
			models: [],
			textures: [],
			roughnessTextures: [],
			normalTextures: []
		}
		this.sourcesReady = 'feetReady'
		this.setDebug()
	}

	setModel(localResources) {
		this.sourceFeet = localResources.items.feetModel
		this.sourceFeetTexture = localResources.items.feetTexture

		if (this.sourceFeetTexture) {
			this.sourceFeetTexture.colorSpace = THREE.SRGBColorSpace
		}

		this.sourceFeetRoughnessTexture = localResources.items.feetRoughnessTexture
		this.sourceFeetNormalTexture = localResources.items.feetNormalTexture
		this.sourceFeetAlphaMap = localResources.items.feetAlphaMap

		this.feetModel = this.sourceFeet.scene
		this.feetModel.bindMode = THREE.DetachedBindMode
		this.feetModel.traverse((child) => {
			if (child.isMesh) {
				child.material.map = this.sourceFeetTexture
				child.material.roughness = 1
				child.material.roughnessMap = this.sourceFeetRoughnessTexture
				child.material.normalMap = this.sourceFeetNormalTexture
				child.material.transparent = true
				child.material.alphaMap = this.sourceFeetAlphaMap
				child.material.needsUpdate = true
			}
		})

		this.container.name = 'Feet'
		this.container.add(this.feetModel)
	}

	setSharedBones(sharedSkeleton) {
		this.feetModel?.traverse((object) => {
			if (object instanceof THREE.SkinnedMesh) {
				object.bind(sharedSkeleton, new THREE.Matrix4())
			}
		})
	}

	clear() {
		this.sources = []
		this.feetModel = null
	}

	resize() {}

	// setDebug() {
	// 	if (!this.debug.active) return
	// }

	update(deltaTime) {}
}
