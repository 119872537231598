import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getCharacter as fetchCharacter, saveCharacter as fetchSaveCharacter } from '@/api/api-qr'
import { useAuthStore } from './auth-store'

export const useQrStore = defineStore('qrStore', () => {
	const character = ref(null)
	const isLoading = ref(false)
	const isValid = ref(null)
	const errorStatus = ref(null)

	const authStore = useAuthStore()

	const getCharacter = async (code) => {
		isLoading.value = true

		const data = await fetchCharacter(authStore.createAuthHeaders(), code)

		if (data?.status) {
			errorStatus.value = data.status
		} else {
			errorStatus.value = null
			character.value = data
		}

		isLoading.value = false
		isValid.value = !!character.value

		if (!isValid.value && !data) {
			errorStatus.value = 'Server Error'
		}
	}

	const saveCharacter = async (code) => {
		isLoading.value = true

		const result = await fetchSaveCharacter(authStore.createAuthHeaders(), code)

		character.value = null
		isLoading.value = false

		return result
	}

	return {
		isLoading,
		isValid,
		errorStatus,
		character,
		getCharacter,
		saveCharacter
	}
})
