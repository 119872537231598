<template>
	<main>
		<Teleport to="body">
			<LoginModal :show="showModal" @close="showModal = false" />
		</Teleport>
	</main>
</template>

<script setup>
import { ref } from 'vue'
import LoginModal from '@/components/Modals/LoginModal.vue'

const showModal = ref(true)
</script>
