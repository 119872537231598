import { ref } from 'vue'
import { useGiftStore } from '@/stores'

export default function useGiftReceiving(giftCode) {
	const giftStore = useGiftStore()
	const status = ref('')
	const gift = ref(null)

	async function getGift() {
		const data = await giftStore.getInfo(giftCode)
		if (data) {
			status.value = 'ReadyToReceive'
			gift.value = data
		} else {
			status.value = 'Error'
		}
	}

	async function receiveGift() {
		const data = await giftStore.receiveGift(giftCode)
		if (data.message) {
			status.value = 'Received'
		} else if (data.status) {
			status.value = data.status
		} else {
			status.value = 'Error'
		}
	}

	return { status, gift, getGift, receiveGift }
}
