import { ref, computed, onMounted } from 'vue'
import { useHeroesStore } from '@/stores/index.js'
import animationTypes from '@/data/animation-types.json'

export default function useUserHeroVariations(rigId, variationNumber) {
	const heroesStore = useHeroesStore()
	const currentIndex = ref(0)

	const currentVariationNumber = computed(() => {
		return heroesStore.currentVariations[currentIndex.value]?.variation_number
	})
	const heroesCount = computed(() => heroesStore.charactersIds.length)
	const currentVariation = computed(() => {
		return heroesStore.currentVariations[currentIndex.value]
	})

	async function setRigId(newRigId) {
		rigId = newRigId
		initCharacter(rigId)
	}

	async function initCharacter(rigId) {
		await heroesStore.fetchCharactersIds(rigId)
		// if (heroesStore.variationsRigId !== rigId) {
		// 	await heroesStore.fetchCharactersIds(rigId)
		// }

		if (currentIndex.value < 0 || currentIndex.value >= heroesCount.value) {
			currentIndex.value = 0
		}

		if (variationNumber) {
			await setVariationNumber(variationNumber)
		} else if (!heroesStore.currentVariations[currentIndex.value]) {
			await heroesStore.fetchCharacter(currentIndex.value)
		}
	}

	async function setVariationNumber(variationNumber) {
		const result = await heroesStore.fetchVariation(rigId, variationNumber)

		if (result === false) {
			await heroesStore.fetchCharacter(currentIndex.value)
			return
		}

		currentIndex.value = result
	}

	async function prevVariation() {
		let prevIndex = currentIndex.value - 1

		if (prevIndex < 0) {
			prevIndex = heroesCount.value - 1
		}

		if (!heroesStore.currentVariations[prevIndex]) {
			await heroesStore.fetchCharacter(prevIndex)
		}

		currentIndex.value = prevIndex
	}

	async function nextVariation() {
		let nextIndex = currentIndex.value + 1

		if (nextIndex >= heroesCount.value) {
			nextIndex = 0
		}

		if (!heroesStore.currentVariations[nextIndex]) {
			await heroesStore.fetchCharacter(nextIndex)
		}

		currentIndex.value = nextIndex
	}

	async function buyAnimation(rigId, animation) {
		const animationIndex = heroesStore.currentVariations[
			currentIndex.value
		].animations.findIndex((el) => animation.id === el.id)

		const result = await heroesStore.buyAnimation(rigId, animation.id)

		if (result === 'success') {
			heroesStore.currentVariations[currentIndex.value].animations[animationIndex].type =
				animationTypes.inUserAnimations
		}
		return result
	}

	async function sellVariation(rigId) {
		return await heroesStore.sellVariation(rigId)
	}

	onMounted(() => {
		if (rigId === undefined) {
			return
		}

		initCharacter(rigId)
	})

	return {
		currentVariation,
		heroesCount,
		currentIndex,
		currentVariationNumber,
		setRigId,
		prevVariation,
		nextVariation,
		buyAnimation,
		sellVariation
	}
}
