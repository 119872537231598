import {
	getStatus as fetchStatus,
	cancel,
	connect,
	setSecondCharacter,
	create,
	confirm
} from '@/api/api-characters-exchanges.js'
import { defineStore } from 'pinia'
import { useAuthStore } from '@/stores'

export const useExchangeStore = defineStore('exchangeStore', () => {
	const authStore = useAuthStore()

	const getStatus = async (variationId) => {
		return await fetchStatus(variationId, authStore.createAuthHeaders())
	}

	const cancelExchange = async (exchangeCode) => {
		return await cancel(exchangeCode, authStore.createAuthHeaders())
	}

	const connectToExchange = async (exchangeCode) => {
		return await connect(exchangeCode, authStore.createAuthHeaders())
	}

	const setCharacter = async (exchangeCode, variationId) => {
		return await setSecondCharacter(exchangeCode, variationId, authStore.createAuthHeaders())
	}

	const createExchange = async (variationId) => {
		return await create(variationId, authStore.createAuthHeaders())
	}

	const confirmExchange = async (exchangeCode) => {
		return await confirm(exchangeCode, authStore.createAuthHeaders())
	}

	return {
		getStatus,
		cancelExchange,
		connectToExchange,
		setCharacter,
		createExchange,
		confirmExchange
	}
})
