import { ref } from 'vue'
import { defineStore } from 'pinia'
import {
	getVariation,
	getVariationById,
	buyAnimationById,
	sellCharacterById
} from '@/api/api-variation.js'
import { useAuthStore } from '@/stores/auth-store.js'
import { useProfileStore } from './profile-store'
import { getRigCharactersIds, getVariationsArray } from '@/api/api-collections.js'

export const useHeroesStore = defineStore('heroesStore', () => {
	const authStore = useAuthStore()
	const profileStore = useProfileStore()

	const heroes = ref([])
	const charactersIds = ref([])
	const currentVariations = ref([])
	const variationsRigId = ref(null)
	const variationsArray = ref([])

	function setHeroes(data) {
		heroes.value = data
	}

	async function fetchCharactersIds(rigId) {
		variationsRigId.value = rigId
		currentVariations.value = []
		variationsArray.value = []

		const data = await getRigCharactersIds(authStore.createAuthHeaders(), rigId)
		charactersIds.value = data.characters_ids
	}

	async function fetchCharacter(index) {
		currentVariations.value[index] = await getVariationById(
			authStore.createAuthHeaders(),
			charactersIds.value[index]
		)
	}

	async function fetchVariation(rigId, variationNumber) {
		const variation = await getVariation(authStore.createAuthHeaders(), rigId, variationNumber)

		const index = charactersIds.value.indexOf(variation?.id)

		if (index === -1) {
			return false
		}

		currentVariations.value[index] = variation

		return index
	}

	async function fetchVariationsArray(rigId) {
		if (variationsArray.value.length > 0) {
			return variationsArray.value
		}

		const result = await getVariationsArray(authStore.createAuthHeaders(), rigId)

		variationsArray.value = result.variations

		return variationsArray.value
	}

	async function buyAnimation(characterId, animationId) {
		const body = {
			character_id: characterId
		}
		const result = await buyAnimationById(
			authStore.createAuthHeaders(),
			animationId,
			JSON.stringify(body)
		)

		if (result && result?.message.startsWith('The animation is purchased')) {
			await profileStore.fetchCurrency()
			return 'success'
		} else if (result && result?.message === 'Not enough energy') {
			return 'no-energy'
		}
		return result
	}

	async function sellVariation(rigId) {
		const result = await sellCharacterById(authStore.createAuthHeaders(), rigId)

		if (result) {
			const variationIndex = currentVariations.value.findIndex((el) => {
				return el?.id === rigId
			})
			const variationIdIndex = charactersIds.value.indexOf(rigId)

			currentVariations.value.splice(variationIndex, 1)
			charactersIds.value.splice(variationIdIndex, 1)

			profileStore.fetchCurrency()

			if (!charactersIds.value.length) {
				return 'no-characters'
			}
			return 'success'

			// const firstCharacterId = charactersIds.value[0]
			// const firstVariationId = currentVariations.value[0]?.id
			//
			// if (firstCharacterId && firstVariationId !== firstCharacterId) {
			// 	// await fetchCharacter(0)
			// } else if (!firstCharacterId) {
			// 	return 'no-characters'
			// }
		}
		return 'error'
	}

	return {
		heroes,
		charactersIds,
		currentVariations,
		variationsRigId,
		setHeroes,
		fetchCharactersIds,
		fetchCharacter,
		fetchVariation,
		fetchVariationsArray,
		buyAnimation,
		sellVariation
	}
})
