<template>
	<div
		class="full-screen-preloader"
		:class="{
			'full-screen-preloader--constructor': constructor,
			'full-screen-preloader--hidden': !loading,
			'full-screen-preloader--translated': store3DModels.isCharacter3DTranslated
		}"
	>
		<PulseLoader :style />
	</div>
</template>

<script setup>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { use3DModelsStore } from '@/stores/index'

defineProps({
	loading: Boolean,
	style: Object,
	constructor: Boolean
})

// const emit=defineEmits('loadingEnd')

const store3DModels = use3DModelsStore()
</script>
