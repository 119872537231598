<template>
	<div :class="canvasClass">
		<canvas id="character-3d" ref="canvasRef" @hide-preloader="handleModelLoad"></canvas>
	</div>
</template>

<script setup>
import { computed } from 'vue'
import { use3DModelsStore } from '@/stores/index'

const store3DModels = use3DModelsStore()

const canvasClass = computed(() => {
	return [
		store3DModels.canvasClass,
		store3DModels.isCanvasVisible ? '' : 'character-3d-model--transparent'
	]
})

const handleModelLoad = () => {
	store3DModels.setPreloaderModelLoading(false)
}
</script>
