<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
		<g opacity="0.5" clip-path="url(#clip0_344_257)">
			<path
				d="M12.5 23.3109C12.3735 23.3109 12.2471 23.2782 12.1338 23.2128C12.0108 23.1418 9.08741 21.4444 6.12209 18.8869C4.36457 17.3712 2.96164 15.8677 1.95237 14.4185C0.646316 12.5432 -0.0104695 10.7394 0.000126176 9.05716C0.0125285 7.09965 0.71365 5.25873 1.97449 3.87348C3.25661 2.46489 4.96765 1.68921 6.7925 1.68921C9.13121 1.68921 11.2694 2.99927 12.5001 5.07455C13.7307 2.99931 15.8689 1.68921 18.2076 1.68921C19.9316 1.68921 21.5765 2.38911 22.8394 3.66001C24.2253 5.05468 25.0128 7.02528 24.9999 9.06644C24.9892 10.7458 24.3201 12.5469 23.0112 14.4196C21.9988 15.868 20.5978 17.3708 18.8472 18.8861C15.8927 21.4434 12.9903 23.1407 12.8682 23.2117C12.7544 23.2778 12.6272 23.3109 12.5 23.3109Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_344_257">
				<rect width="25" height="25" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
</template>
