export default [
	// {
	// 	name: 'armatureModel',
	// 	type: 'gltfModel',
	// 	path: 'https://api.pechenka.e2qr.com/storage/characters/skeleton/rig.glb'
	// },
	// {
	// 	name: 'headModelTest',
	// 	type: 'gltfModel',
	// 	path: '/models/test/head.glb'
	// },
	// {
	// 	name: 'bodyModel',
	// 	type: 'gltfModel',
	// 	path: '/models/test/body.glb'
	// },
	// {
	// 	name: 'legsModel',
	// 	type: 'gltfModel',
	// 	path: '/models/test/legs.glb'
	// },
	// {
	// 	name: 'feetModel',
	// 	type: 'gltfModel',
	// 	path: '/models/test/feet.glb'
	// },
	// {
	// 	name: 'skeletonModel',
	// 	type: 'gltfModel',
	// 	path: '/models/2.glb'
	// },
	// {
	// 	name: 'displacementTexture',
	// 	type: 'texture',
	// 	path: '/textures/displacement.jpg'
	// },
	{
		name: 'hdrTexture',
		type: 'rgbeTexture',
		path: '/textures/studio_small_02_1k.hdr'
	}
]
