<template>
	<div class="card-scroller" ref="cardScroller" style="height: 100%">
		<div class="card-scroller__spacer" ref="scrollSpacer"></div>

		<div class="card-stack" ref="cardsStack">
			<DescriptionCard
				v-for="(card, index) in cards"
				:index
				:card="card"
				:key="card.id"
				:ref="(el) => (cardRefs[index] = el)"
				@clickOnCard="emitCardClick"
			/>
		</div>
	</div>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import DescriptionCard from '@/components/Character/DescriptionCard.vue'
import Scrollbar from 'smooth-scrollbar'

gsap.registerPlugin(ScrollTrigger)

defineProps({
	cards: {
		type: Array,
		required: true
	}
})

const cardRefs = ref([])
const cardScroller = ref(null)
const cardsStack = ref(null)
const scrollSpacer = ref(null)

let scrolbar = null

const setScrollElementsHeight = () => {
	let cardElements = cardRefs.value.map((component) => component.$refs.cardElement)
	scrollSpacer.value.style.height = 'auto'
	cardsStack.value.style.height = 'auto'

	let scrollerHeight = cardScroller.value.offsetHeight
	let allCardsHeight = 0
	cardElements.forEach((card) => {
		allCardsHeight += card.offsetHeight
	})
	scrollSpacer.value.style.height = `${allCardsHeight}px`
	cardsStack.value.style.height = `${scrollerHeight}px`
}

onMounted(() => {
	scrolbar = Scrollbar.init(cardScroller.value, {})
	let cardElements = cardRefs.value.map((component) => component.$refs.cardElement)

	let cardOffset =
		(cardScroller.value.offsetHeight - cardElements[0].offsetHeight) / (cardElements.length - 1)
	let scrollerHeight = cardScroller.value.offsetHeight
	let allCardsHeight = 0
	const tl = gsap.timeline()

	setScrollElementsHeight()
	window.addEventListener('resize', setScrollElementsHeight)
	tl.addLabel('start', 0)
	cardElements.forEach((card, index) => {
		allCardsHeight += card.offsetHeight

		if (index === 0) return

		tl.to(
			card,
			{
				y: `${-(card.offsetHeight * index) + cardOffset * index}px`,
				duration: index * 1,
				ease: 'none'
			},
			'start'
		)
	})
	tl.pause()
	scrollSpacer.value.style.height = `${allCardsHeight}px`
	cardsStack.value.style.height = `${scrollerHeight}px`

	scrolbar.addListener((e) => {
		const progress = e.offset.y / e.limit.y
		tl.progress(progress)
		cardsStack.value.style.transform = `translateY(${e.offset.y}px)`
	})
})

onBeforeUnmount(() => {
	scrolbar.destroy()
	window.removeEventListener('resize', setScrollElementsHeight)
})

const emit = defineEmits(['clickOnCard'])
const emitCardClick = (card) => {
	emit('clickOnCard', card)
}
</script>
