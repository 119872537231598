import { HOST, OPTIONS, request } from './index'

export const getCharacters = async () => {
	const url = `${HOST}/rigs/all`

	return request(async () => {
		const response = await fetch(url, OPTIONS)
		if (response.ok) {
			const data = await response.json()

			return data?.rigs
		}
	})
}

export const getCharacterById = async (characterId) => {
	const url = `${HOST}/rigs/${characterId}`

	return request(async () => {
		const response = await fetch(url, OPTIONS)
		if (response.ok) {
			const data = await response.json()
			return data
		}
	})
}
