import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getFavourites, postFavourite, deleteFavourite } from '../api/api-favourites'
import { useAuthStore } from './auth-store'

export const useFavouritesStore = defineStore('favouritesStore', () => {
	const isLoading = ref(false)

	const authStore = useAuthStore()

	const favourites = ref([])
	const favouritesStats = ref([])
	const favouriteCurrent = ref(null)
	const favouriteFetched = ref(false)

	const fetchFavourites = async () => {
		isLoading.value = true

		favourites.value = await getFavourites(authStore.createAuthHeaders())

		favouritesStats.value = favourites.value.reduce((acc, favourite) => {
			const found = acc.find((el) => el.name_ru === favourite.character.rig.name_ru)
			if (!found) {
				acc.push({
					id: favourite.character.rig.id,
					name_ru: favourite.character.rig.name_ru,
					preview_src: favourite.character.rig.preview_src,
					design_options: {
						bg_gradient: favourite.character.rig.design_options.bg_gradient,
						named_bg_src: favourite.character.rig.design_options.named_bg_src
					}
				})
			}
			return acc
		}, [])

		isLoading.value = false
	}

	const addToFavourites = async (variationId) => {
		const body = {
			character_id: variationId
		}
		const result = await postFavourite(authStore.createAuthHeaders(), JSON.stringify(body))
		return result
	}

	const removeFromFavourites = async (variationId) => {
		const body = {
			character_id: variationId
		}

		const result = await deleteFavourite(authStore.createAuthHeaders(), JSON.stringify(body))
		return result
	}

	const setFavouriteCurrent = async (value) => {
		favouriteCurrent.value = value
		favouriteFetched.value = true
	}

	const clearStore = () => {
		favourites.value = []
		favouritesStats.value = []
		favouriteCurrent.value = null
		favouriteFetched.value = false
	}

	return {
		isLoading,
		favourites,
		favouritesStats,
		favouriteCurrent,
		favouriteFetched,
		fetchFavourites,
		addToFavourites,
		removeFromFavourites,
		setFavouriteCurrent,
		clearStore
	}
})
