import { ref } from 'vue'
import { defineStore } from 'pinia'
import { initScene } from '@/three/index.js'
import Experience from '@/three/Experience/Experience'

export const use3DModelsStore = defineStore('3DModelsStore', () => {
	const canvasClass = ref('character-3d-model')
	const isCanvasVisible = ref(false)
	const isPreloaderModelLoading = ref(true)
	const isCharacter3DTranslated = ref(false)
	const is3DWorldReady = ref(false)
	const currentAnimationName = ref('')
	const currentVariation = ref(null)

	const init3DScene = (canvasId = 'character-3d') => {
		initScene({ canvas: canvasId })
	}

	const getInstanceCurrentCharacter = () => {
		return Experience.getInstance().world?.character
	}

	const setCharacter3DTranslated = (value) => {
		if (value !== isCharacter3DTranslated.value) {
			isCharacter3DTranslated.value = value

			const character3DClassInstance = Experience.getInstance().world?.character
			character3DClassInstance.translateModel(isCharacter3DTranslated.value)
		}
	}

	const setPreloaderModelLoading = (preloaderVisible, canvasVisible = !preloaderVisible) => {
		setCanvasVisible(canvasVisible)
		isPreloaderModelLoading.value = preloaderVisible
	}

	const toggleModelUpdate = (value) => {
		const time3DClassInstance = Experience.getInstance()?.time
		if (time3DClassInstance) {
			time3DClassInstance.togglePlaying(value)
		}
	}

	const setCanvasClass = (value) => {
		canvasClass.value = value
	}

	const setCanvasVisible = (value) => {
		isCanvasVisible.value = value
	}

	const load3DModels = async (variation) => {
		currentVariation.value = variation

		const character3DClassInstance = Experience.getInstance().world?.character
		const environment = Experience.getInstance().world?.environment

		const characterRigSrc = variation?.rig?.src
		const currentCharacterItems = variation?.items?.current
		const hdrMapSrc = variation?.rig?.hdr_map_src

		if (character3DClassInstance && characterRigSrc && currentCharacterItems && hdrMapSrc) {
			environment.loadHDRMap(hdrMapSrc)
			await character3DClassInstance.loadModels([
				{
					categoryName: 'rig',
					model: [
						{
							name: 'rigModel',
							type: 'gltfModel',
							path: characterRigSrc
						}
					]
				},
				{
					categoryName: 'head',
					model: [
						{
							name: 'headModel',
							type: 'gltfModel',
							path: currentCharacterItems.head.src
						},
						{
							name: 'headTexture',
							type: 'texture',
							path: currentCharacterItems.head.base_color
						},
						{
							name: 'headRoughnessTexture',
							type: 'texture',
							path: currentCharacterItems.head.roughness
						},
						{
							name: 'headNormalTexture',
							type: 'texture',
							path: currentCharacterItems.head.normal
						},
						{
							name: 'headAlphaMap',
							type: 'texture',
							path: currentCharacterItems.head.alpha
						}
					]
				},
				{
					categoryName: 'accessory',
					model: [
						{
							name: 'accessoryModel',
							type: 'gltfModel',
							path: currentCharacterItems.accessory.src
						},
						{
							name: 'accessoryTexture',
							type: 'texture',
							path: currentCharacterItems.accessory.base_color
						},
						{
							name: 'accessoryRoughnessTexture',
							type: 'texture',
							path: currentCharacterItems.accessory.roughness
						},
						{
							name: 'accessoryNormalTexture',
							type: 'texture',
							path: currentCharacterItems.accessory.normal
						},
						{
							name: 'accessoryAlphaMap',
							type: 'texture',
							path: currentCharacterItems.accessory.alpha
						}
					]
				},
				{
					categoryName: 'body',
					model: [
						{
							name: 'bodyModel',
							type: 'gltfModel',
							path: currentCharacterItems.body.src
						},
						{
							name: 'bodyTexture',
							type: 'texture',
							path: currentCharacterItems.body.base_color
						},
						{
							name: 'bodyRoughnessTexture',
							type: 'texture',
							path: currentCharacterItems.body.roughness
						},
						{
							name: 'bodyNormalTexture',
							type: 'texture',
							path: currentCharacterItems.body.normal
						},
						{
							name: 'bodyAlphaMap',
							type: 'texture',
							path: currentCharacterItems.body.alpha
						}
					]
				},
				{
					categoryName: 'legs',
					model: [
						{
							name: 'legsModel',
							type: 'gltfModel',
							path: currentCharacterItems.legs.src
						},
						{
							name: 'legsTexture',
							type: 'texture',
							path: currentCharacterItems.legs.base_color
						},
						{
							name: 'legsRoughnessTexture',
							type: 'texture',
							path: currentCharacterItems.legs.roughness
						},
						{
							name: 'legsNormalTexture',
							type: 'texture',
							path: currentCharacterItems.legs.normal
						},
						{
							name: 'legsAlphaMap',
							type: 'texture',
							path: currentCharacterItems.legs.alpha
						}
					]
				},
				{
					categoryName: 'feet',
					model: [
						{
							name: 'feetModel',
							type: 'gltfModel',
							path: currentCharacterItems.feet.src
						},
						{
							name: 'feetTexture',
							type: 'texture',
							path: currentCharacterItems.feet.base_color
						},
						{
							name: 'feetRoughnessTexture',
							type: 'texture',
							path: currentCharacterItems.feet.roughness
						},
						{
							name: 'feetNormalTexture',
							type: 'texture',
							path: currentCharacterItems.feet.normal
						},
						{
							name: 'feetAlphaMap',
							type: 'texture',
							path: currentCharacterItems.feet.alpha
						}
					]
				}
			])
		}
	}

	const load3DModel = (variation, categoryName) => {
		const character3DClass = Experience.getInstance().world?.character
		const currentCharacterItem = variation?.items?.current[categoryName]

		character3DClass.loadModel(categoryName, [
			{
				name: `${categoryName}Model`,
				type: 'gltfModel',
				path: currentCharacterItem.src
			},
			{
				name: `${categoryName}Texture`,
				type: 'texture',
				path: currentCharacterItem.base_color
			},
			{
				name: `${categoryName}RoughnessTexture`,
				type: 'texture',
				path: currentCharacterItem.roughness
			},
			{
				name: `${categoryName}NormalTexture`,
				type: 'texture',
				path: currentCharacterItem.normal
			},
			{
				name: `${categoryName}AlphaMap`,
				type: 'texture',
				path: currentCharacterItem.alpha
			}
		])
	}

	const startAnimation = (animationName) => {
		if (animationName && animationName !== currentAnimationName.value) {
			currentAnimationName.value = animationName
			const character3DClass = Experience.getInstance().world?.character
			character3DClass.startAnimation(animationName)
		}
	}
	const stopAnimation = () => {
		const character3DClass = Experience.getInstance().world?.character
		if (character3DClass && currentAnimationName.value) {
			character3DClass.stopAnimation()
			currentAnimationName.value = ''
		}
	}

	const toggleRotation = (value) => {
		const character3DClass = Experience.getInstance().world?.character
		if (character3DClass) {
			character3DClass.toggleRotation(value)
		}
	}

	const resetAutoRotate = () => {
		const character3DClass = Experience.getInstance().world?.character
		if (character3DClass) {
			character3DClass.resetRotation()
		}
	}

	const enableZooming = () => {
		window.dispatchEvent(new CustomEvent('3d-camera:enable-zooming'))
	}

	const disableZooming = () => {
		window.dispatchEvent(new CustomEvent('3d-camera:disable-zooming'))
	}

	const getImageUrl = () => {
		const canvas = Experience.getInstance().canvas
		return canvas.toDataURL('image/png')
	}

	window.addEventListener('3d-app:ready', () => {
		is3DWorldReady.value = true
	})

	return {
		canvasClass,
		isCanvasVisible,
		isCharacter3DTranslated,
		isPreloaderModelLoading,
		is3DWorldReady,
		currentAnimationName,
		currentVariation,
		init3DScene,
		getInstanceCurrentCharacter,
		setCanvasClass,
		setCanvasVisible,
		setCharacter3DTranslated,
		setPreloaderModelLoading,
		load3DModels,
		load3DModel,
		startAnimation,
		stopAnimation,
		toggleModelUpdate,
		enableZooming,
		disableZooming,
		resetAutoRotate,
		getImageUrl,
		toggleRotation
	}
})
