<template>
	<AppModal :show="show" @close="emit('close')" ref="modal" :customClass="'modal-green-success'">
		<template #content>
			<h3 class="modal-green-success__heading modal-heading font-heading center m-0">
				Поздравляем
			</h3>

			<img src="/images/hello-modal.png" class="modal-hello__img" alt="" />

			<p v-if="text" class="modal-green-success__text modal-text center m-0">
				{{ text }}
			</p>

			<router-link v-if="linkHref" :to="linkHref" custom v-slot="{ navigate }">
				<button class="modal-green-success__button button button-primary" @click="navigate">
					{{ linkText }}
				</button>
			</router-link>

			<button
				v-else
				class="modal-green-success__button button button-primary"
				@click="emit('close')"
			>
				{{ linkText }}
			</button>
		</template>
	</AppModal>
</template>

<script setup>
import AppModal from '@/components/AppModal.vue'

defineProps({
	show: {
		type: Boolean,
		default: false
	},
	text: {
		type: String,
		default: ''
	},
	linkText: {
		type: String,
		default: 'Ок'
	},
	linkHref: {
		type: String
	}
})

const emit = defineEmits(['close'])
</script>
