import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getCharacters, getCharacterById } from '../api/api-characters'

export const useCharactersStore = defineStore('charactersStore', () => {
	const characters = ref([])
	const currentCharacterId = ref('')
	const currentCharacterName = ref('')
	const currentCharacter = ref(null)
	const variationsCount = ref(null)
	const isLoading = ref(false)

	const fetchCharacters = async () => {
		isLoading.value = true

		characters.value = await getCharacters()

		isLoading.value = false
	}

	const fetchCharacterById = async (id) => {
		isLoading.value = true

		const character = await getCharacterById(id)
		if (character) {
			currentCharacter.value = character
			currentCharacterName.value = character.name_ru
			currentCharacterId.value = character.id
			variationsCount.value = character.variations_count
		}

		isLoading.value = false
	}

	return {
		characters,
		variationsCount,
		isLoading,
		currentCharacterId,
		currentCharacterName,
		currentCharacter,
		fetchCharacters,
		fetchCharacterById
	}
})
