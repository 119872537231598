import * as THREE from 'three'
import Experience from './Experience.js'
import Sizes from './Utils/Sizes.js'

export default class State {
	static _instance = null

	static getInstance() {
		return State._instance || new State()
	}

	experience = Experience.getInstance()
	renderer = this.experience.renderer.instance
	postprocessing = false

	unrealBloom = {
		enabled: false,
		strength: 0.6,
		radius: 1.9,
		threshold: 0.362
	}

	motionBlur = {
		enabled: false,
		cameraBlur: true,
		animate: true,
		samples: 8,
		expandGeometry: 1,
		interpolateGeometry: 1,
		smearIntensity: 5,
		speed: 20,
		renderTargetScale: 1,
		jitter: 1,
		jitterStrategy: 2
	}

	constructor(renderer, scene, camera, canvas, sizes) {
		// Singleton
		if (State._instance) {
			return State._instance
		}
		State._instance = this

		this.experience = Experience.getInstance()
		this.renderer = renderer
		this.scene = scene
		this.camera = camera
		this.canvas = canvas
		this.sizes = sizes
		this.floatType = this.renderer?.capabilities?.isWebGL2
			? THREE.FloatType
			: THREE.HalfFloatType

		this.setLayers()
	}

	setLayers() {
		this.layersConst = {
			BLOOM_SCENE: 1,
			DEFAULT: 0
		}
		this.bloomLayer = new THREE.Layers()
		this.bloomLayer.set(this.layersConst.BLOOM_SCENE)
	}

	resize() {}
}
