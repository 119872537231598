import { HOST, request } from './index'

const OPTIONS = {
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json'
	}
}

export const sendCode = async (phoneNumber) => {
	const url = `${HOST}/auth/send_code`

	return request(async () => {
		const response = await fetch(url, {
			...OPTIONS,
			body: JSON.stringify({
				phone_number: phoneNumber
			})
		})

		if (response.ok) {
			return await response.json()
		} else if (response.status === 400) {
			const data = await response.json()

			if (data.wait_seconds) {
				return data
			}
		}

		return false
	})
}

export const sendPassword = async (phoneNumber) => {
	const url = `${HOST}/auth/send_password`

	return request(async () => {
		const response = await fetch(url, {
			...OPTIONS,
			body: JSON.stringify({
				phone_number: phoneNumber
			})
		})

		if (response.ok) {
			return await response.json()
		} else if (response.status === 400) {
			const data = await response.json()

			if (data.wait_seconds) {
				return data
			}
		}

		return false
	})
}

export const singUp = async (phoneNumber, code) => {
	const url = `${HOST}/auth/sign_up`

	return request(async () => {
		const response = await fetch(url, {
			...OPTIONS,
			body: JSON.stringify({
				phone_number: phoneNumber,
				code: code
			})
		})

		if (response.ok) {
			const data = await response.json()
			return data
		}

		return false
	})
}

export const singIn = async (phoneNumber, password) => {
	const url = `${HOST}/auth/sign_in`

	return request(async () => {
		const response = await fetch(url, {
			...OPTIONS,
			body: JSON.stringify({
				phone_number: phoneNumber,
				password: password
			})
		})

		if (response.ok) {
			const data = await response.json()
			return data
		}

		return false
	})
}
