<template>
	<main>
		<TabsComponent :tabs="tabs">
			<template #constructor-button> Коллекция </template>

			<template #character-button> Мои герои </template>

			<template #favourite-button>
				<span class="icon"><IconLike /></span>
			</template>

			<template #constructor>
				<CardsScroller
					v-if="charactersStore.characters?.length"
					:cards="charactersStore.characters"
					@clickOnCard="handleCollectionCardClick"
				/>
			</template>

			<template #character>
				<CardsScroller
					v-if="heroes?.length"
					:cards="heroes"
					@clickOnCard="handleMyHeroesCardClick"
				/>
			</template>

			<template #favourite>
				<CardsScroller
					v-if="favouriteStore.favouritesStats?.length"
					:cards="favouriteStore.favouritesStats"
					@clickOnCard="handleLikedCardClick"
				/>
			</template>
		</TabsComponent>

		<Teleport to="body">
			<ErrorModal
				:show="showErrorModal"
				:text="errorModalText"
				@close="showErrorModal = false"
			/>

			<!-- Gifting -->
			<GiftModal
				:show="showGiftModal"
				:image="giftImage"
				:rig="gift?.rig"
				title="Вы хотите принять подарок?"
				confirm-text="Принять"
				@close="showGiftModal = false"
				@confirm="confirmGifting"
			/>
			<GreenSuccessModal
				:show="showGiftGreenSuccessModal"
				text="Подарок получен, вы можете найти его в своей коллекции"
				link-text="Моя коллекция"
				@close="onCloseGiftGreenSuccessModal"
			/>

			<!-- Exchanging -->
			<ExchangeModal
				:show="showExchangeModal"
				:initiatorCharacter="exchangeCharacter"
				:initiator-character-image="exchangeCharacterImage"
				:receiving-character="receivingCharacter"
				:receiving-character-image="currentCharacterImage"
				:waiting="exchangeStatus === 'Confirmation'"
				user-role="receiving"
				@close="onCloseExchangeModal"
				@confirm="onConfirmExchangeModal"
			/>
			<SelectCollectionModal
				v-model:current-collection="currentCollection"
				:show="showSelectCollectionModal"
				:collections="heroes"
				@close="onCloseSelectCollectionModal"
				@confirm="onConfirmSelectCollectionModal"
			/>
			<SelectCharacterModal
				v-model:current-character="currentCharacter"
				:show="showSelectCharacterModal"
				:show-preloader="isPreloaderShown"
				:rig="currentCollection"
				@close="onCloseSelectCharacterModal"
				@confirm="onConfirmSelectCharacterModal"
			/>
			<GreenSuccessModal
				:show="showExchangeGreenSuccessModal"
				text="Обмен успешно завершен"
				link-text="Моя коллекция"
				@close="onCloseExchangeGreenSuccessModal"
			/>
		</Teleport>
	</main>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'

import { useCharactersStore, useFavouritesStore } from '@/stores/index'

import useUserHeroes from '@/composables/useUserHeroes.js'
import useGiftReceiving from '@/composables/useGiftReceiving.js'
import useExchangeReceiving from '@/composables/useExchangeReceiving.js'
import use3DScene from '@/composables/use3DScene.js'

import ExchangeModal from '@/components/Modals/ExchangeModal.vue'
import CardsScroller from '@/components/CardsScroller.vue'
import TabsComponent from '@/components/TabsComponent.vue'
import IconLike from '@/components/Icons/IconLike.vue'
import GiftModal from '@/components/Modals/GiftModal.vue'
import ErrorModal from '@/components/Modals/ErrorModal.vue'
import GreenSuccessModal from '@/components/Modals/GreenSuccessModal.vue'
import SelectCollectionModal from '@/components/Modals/SelectCollectionModal.vue'
import SelectCharacterModal from '@/components/Modals/SelectCharacterModal.vue'

import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const charactersStore = useCharactersStore()
const favouriteStore = useFavouritesStore()

const tabs = [{ name: 'constructor' }, { name: 'character' }, { name: 'favourite' }]

const showErrorModal = ref(false)
const errorModalText = ref('')
const currentAction = ref('')

// Gifting
const showGiftModal = ref(false)
const showGiftGreenSuccessModal = ref(false)
const giftImage = ref('')

// Exchanging
const showExchangeModal = ref(false)
const exchangeCharacterImage = ref('')
const choiceInProcess = ref(false)
const showSelectCollectionModal = ref(false)
const showSelectCharacterModal = ref(false)
const currentCollection = ref(null)
const currentCharacter = ref(null)
const currentCharacterImage = ref('')
const showExchangeGreenSuccessModal = ref(false)
const receivingCharacter = computed(() => {
	if (choiceInProcess.value) {
		return currentCharacter.value
	}
	return null
})

const { heroes } = useUserHeroes()
const {
	status: giftStatus,
	gift,
	getGift,
	receiveGift
} = useGiftReceiving(route.query['gift-code'])
const {
	status: exchangeStatus,
	exchangeCharacter,
	connectToExchanging,
	setCharacter,
	stopExchanging,
	cancelExchanging
} = useExchangeReceiving(route.query['exchange-code'])

const sceneVariation = computed(() => {
	if (exchangeStatus.value === 'Pending') {
		return choiceInProcess.value ? currentCharacter.value : exchangeCharacter.value
	} else {
		return gift.value
	}
})

const { isPreloaderShown, placeSceneOverModal, resetScenePosition, getVariationImage } =
	use3DScene(sceneVariation)

onMounted(() => {
	if (!charactersStore.characters.length) {
		charactersStore.fetchCharacters()
	}

	favouriteStore.fetchFavourites()

	if (route.query['gift-code']) {
		currentAction.value = 'gifting'
		getGift()
	} else if (route.query['exchange-code']) {
		currentAction.value = 'exchanging'
		router.replace({ name: 'Home' })
		connectToExchanging()
	}
})

watch(showErrorModal, (newValue) => {
	if (!newValue) {
		errorModalText.value = ''
	}
})

const handleCollectionCardClick = (card) => {
	router.push({
		name: 'Constructor',
		query: {
			rig: card.id,
			variation: 1
		}
	})
}

const handleMyHeroesCardClick = (card) => {
	router.push({
		name: 'Character',
		query: {
			rig: card.id
		}
	})
}

const handleLikedCardClick = async (card) => {
	router.push({
		name: 'Favourite',
		query: {
			rig: card.id
		}
	})
}

// Gifting
watch(isPreloaderShown, (newValue) => {
	if (!newValue && currentAction.value === 'gifting') {
		setTimeout(() => {
			giftImage.value = getVariationImage()
		}, 300)
	}
})
watch(giftStatus, (newValue) => {
	switch (newValue) {
		case 'ReadyToReceive':
			showGiftModal.value = true
			break
		case 'Received':
			showGiftModal.value = false
			showGiftGreenSuccessModal.value = true
			router.replace({ name: 'Home' })
			break
		case 'CharacterExist':
			showGiftModal.value = false
			errorModalText.value = 'Персонаж уже присутствует в коллекции'
			showErrorModal.value = true
			router.replace({ name: 'Home' })
			break
		case 'Error':
			showGiftModal.value = false
			showErrorModal.value = true
			router.replace({ name: 'Home' })
			break
	}
})
function confirmGifting() {
	receiveGift()
}
function onCloseGiftGreenSuccessModal() {
	router.push({
		name: 'Character',
		query: {
			rig: gift.value.rig.id,
			variation: gift.value.variation_number
		}
	})
}

// Exchanging
watch(exchangeStatus, (newValue) => {
	switch (newValue) {
		case 'Pending':
			showExchangeModal.value = true
			break
		case 'Confirmation':
			break
		case 'Canceled':
			showExchangeModal.value = false
			showSelectCollectionModal.value = false
			showSelectCharacterModal.value = false
			showErrorModal.value = true
			stopExchanging()
			break
		case 'Exchanged':
			showExchangeModal.value = false
			showExchangeGreenSuccessModal.value = true
			stopExchanging()
			break
		case 'CharacterExist':
			showExchangeModal.value = false
			errorModalText.value = 'Персонаж уже присутствует в коллекции'
			showErrorModal.value = true
			stopExchanging()
			router.replace({ name: 'Home' })
			break
		case 'Error':
			showExchangeModal.value = false
			showSelectCollectionModal.value = false
			showSelectCharacterModal.value = false
			showErrorModal.value = true
			stopExchanging()
			router.replace({ name: 'Home' })
			break
	}
})
watch(isPreloaderShown, (newValue) => {
	if (
		!newValue &&
		currentAction.value === 'exchanging' &&
		exchangeStatus.value === 'Pending' &&
		!exchangeCharacterImage.value
	) {
		setTimeout(() => {
			exchangeCharacterImage.value = getVariationImage()
		}, 300)
	}
})
watch(showSelectCharacterModal, (newValue) => {
	if (newValue) {
		placeSceneOverModal('select-character-modal')
	} else {
		resetScenePosition()
	}
})
function onCloseExchangeModal() {
	showExchangeModal.value = false
	stopExchanging()
	cancelExchanging()
}
function onConfirmExchangeModal() {
	if (exchangeStatus.value === 'Pending') {
		if (choiceInProcess.value) {
			setCharacter(currentCharacter.value.id)
		} else {
			choiceInProcess.value = true
			showExchangeModal.value = false
			showSelectCollectionModal.value = true
		}
	}
}
function onCloseSelectCollectionModal() {
	showSelectCollectionModal.value = false
	// choiceInProcess.value = false
	stopExchanging()
	cancelExchanging()
}
function onConfirmSelectCollectionModal() {
	showSelectCollectionModal.value = false
	showSelectCharacterModal.value = true
}
function onCloseExchangeGreenSuccessModal() {
	showExchangeGreenSuccessModal.value = false
	const rig = exchangeCharacter.value.rig.id
	const variation = exchangeCharacter.value.variation_number
	window.location.href = `${window.location.origin}/character?rig=${rig}&variation=${variation}`
}
function onCloseSelectCharacterModal() {
	showSelectCharacterModal.value = false
	showSelectCollectionModal.value = true
}
function onConfirmSelectCharacterModal() {
	currentCharacterImage.value = getVariationImage()
	showSelectCharacterModal.value = false
	showExchangeModal.value = true
}
</script>
