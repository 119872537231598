<template>
	<Transition name="modal">
		<div v-if="show" class="modal" :class="customClass">
			<div class="modal__backdrop" @click="emitClose"></div>

			<div class="modal__container" ref="modalContainer" :style="customStyle">
				<slot name="content"></slot>
			</div>
		</div>
	</Transition>
</template>

<script setup>
import { ref, nextTick } from 'vue'

defineProps({
	show: {
		type: Boolean,
		default: false
	},
	customClass: {
		type: String,
		default: ''
	},
	customStyle: {
		type: Object
	}
})

const modalContainer = ref(null)

const emit = defineEmits(['close'])

const emitClose = () => {
	emit('close')
}

const getModalContainer = async () => {
	await nextTick()
	return modalContainer.value
}

defineExpose({
	getModalContainer
})
</script>
