import { ref } from 'vue'
import { defineStore } from 'pinia'
import {
	sendCode as fetchSendCode,
	singUp as fetchSignUp,
	singIn as fetchSignIn,
	sendPassword as fetchSendPassword
} from '../api/api-auth'

export const useAuthStore = defineStore('authStore', () => {
	const isLoading = ref(false)
	const accessToken = ref(
		process.env.NODE_ENV === 'development'
			? '2|3CjxgCyZDhuUY7KIzjHLTnKA63oSb4I3jDQchLS698fc4006'
			: getAccessToken()
	)

	const isAuthenticated = ref(!!accessToken.value)

	const createAuthHeaders = () => {
		if (isAuthenticated.value) {
			return {
				Authorization: `Bearer ${accessToken.value}`,
				Accept: 'application/json'
			}
		}

		return {
			Accept: 'application/json'
		}
	}

	const sendCode = async (phoneNumber) => {
		isLoading.value = true

		const result = await fetchSendCode(phoneNumber)

		isLoading.value = false

		return result
	}

	const signUp = async (phoneNumber, code) => {
		isLoading.value = true

		const result = await fetchSignUp(phoneNumber, code)

		isLoading.value = false

		if (result.access_token) {
			saveAccessToken(result.access_token)
		}

		return result !== false
	}

	const signIn = async (phoneNumber, code) => {
		isLoading.value = true

		const result = await fetchSignIn(phoneNumber, code)

		isLoading.value = false

		if (result.access_token) {
			saveAccessToken(result.access_token)
		}

		return result !== false
	}

	const sendCodeAgain = async (phoneNumber) => {
		isLoading.value = true

		const result = await fetchSendCode(phoneNumber)

		isLoading.value = false

		return result
	}

	const sendPassword = async (phoneNumber) => {
		isLoading.value = true

		const result = await fetchSendPassword(phoneNumber)

		isLoading.value = false

		return result
	}

	function saveAccessToken(token) {
		accessToken.value = token
		isAuthenticated.value = true
		localStorage.setItem('access_token', token)
	}

	function getAccessToken() {
		return localStorage.getItem('access_token')
	}

	return {
		isLoading,
		accessToken,
		isAuthenticated,
		createAuthHeaders,
		sendCode,
		signUp,
		signIn,
		sendCodeAgain,
		sendPassword
	}
})
