import { computed, watchEffect } from 'vue'
import { useAuthStore } from '@/stores/index.js'
import { useRoute, useRouter } from 'vue-router'

export default function useAuthHandler() {
	const routesWithoutAuthHandling = ['Login', 'Gift']

	const authStore = useAuthStore()

	const router = useRouter()
	const route = useRoute()

	const enableAuthHandling = computed(() => {
		return route.name === undefined ? false : !routesWithoutAuthHandling.includes(route.name)
	})

	watchEffect(() => {
		if (!authStore.isAuthenticated && enableAuthHandling.value) {
			router.push(`/login?returnUrl=${route.fullPath}`)
		}
	})

	watchEffect(() => {
		if (authStore.isAuthenticated && route.name === 'Login') {
			window.location.href = route.query.returnUrl ?? '/'
		}
	})
}
