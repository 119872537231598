<template>
	<div class="country-phone">
		<div class="country-phone__country-selector">
			<div class="country-phone__selected-country" @click="toggleDropdown">
				<img
					:src="selectedCountry.flag"
					alt="flag"
					class="country-phone__selected-country-flag"
				/>

				<IconDropDown class="country-phone__selected-country-dropdown" />

				<span class="country-phone__selected-country-code">{{ selectedCountry.code }}</span>
			</div>

			<!-- <div v-if="dropdownOpen" class="country-phone__country-dropdown">
				<div
					v-for="country in countries"
					:key="country.code"
					class="country-phone__country-item"
					@click="selectCountry(country)"
				>
					<img :src="country.flag" alt="flag" class="country-phone__country-item-flag" />
					<span class="country-phone__country-item-code">{{ country.code }}</span>
				</div>
			</div> -->
		</div>

		<input
			class="country-phone__input"
			:placeholder="selectedCountry.placeholder"
			v-model="phoneNumber"
			v-maska="selectedCountry.mask"
			@blur="onBlurHandler"
		/>
	</div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import countries from '@/data/countries.json'
import { vMaska } from 'maska/vue'
import IconDropDown from '@/components/Icons/IconDropDown.vue'

const emit = defineEmits(['validation'])

const clearPhoneNumber = defineModel()

const dropdownOpen = ref(false)
const selectedCountry = ref(countries[0])
const phoneNumber = ref('')

const toggleDropdown = () => {
	dropdownOpen.value = !dropdownOpen.value
}

const selectCountry = (country) => {
	selectedCountry.value = country
	phoneNumber.value = ''
	dropdownOpen.value = false
}

const formatPhoneNumber = () => {
	let cleaned = ('' + phoneNumber.value).replace(/\D/g, '')
	let formattedNumber = ''
	let format = selectedCountry.value.placeholder
	let numberIndex = 0

	for (let i = 0; i < format.length; i++) {
		if (format[i] === 'X') {
			if (numberIndex < cleaned.length) {
				formattedNumber += cleaned[numberIndex]
				numberIndex++
			} else {
				formattedNumber += 'X'
			}
		} else {
			formattedNumber += format[i]
		}
	}

	phoneNumber.value = formattedNumber
}

const onBlurHandler = () => {
	let regexp = selectedCountry.value.regexp
	const pattern = new RegExp(regexp)
	let isPhoneValid = pattern.test(phoneNumber.value)
	if (!isPhoneValid) {
		emit('validation', { validation: 'failed' })
	} else {
		emit('validation', { validation: 'success' })
	}
}

watchEffect(() => {
	clearPhoneNumber.value = (selectedCountry.value.code + phoneNumber.value).replace(/\D+/g, '')
})
</script>
