import { ref } from 'vue'

export default function useOrientationHelper() {
	const isPortrait = ref(isScreenPortrait())

	window.screen.orientation.addEventListener('change', () => {
		if (isScreenPortrait()) {
			isPortrait.value = true
		}
	})

	function isScreenPortrait() {
		return window.screen.orientation.angle % 180 == 0
	}

	return { isPortrait }
}
