import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import gsap from 'gsap'

export default class Camera {
	constructor() {
		this.experience = new Experience()
		this.sizes = this.experience.sizes
		this.scene = this.experience.scene
		this.time = this.experience.time
		this.canvas = this.experience.canvas
		this.timeline = this.experience.timeline
		this.cursorEnabled = false

		this.zooming = false
		this.zoomAnimation = null

		this.lerpVector = new THREE.Vector3()

		this.setInstance()
		this.setControls()
		this.addEventListeners()
	}

	setInstance() {
		this.instance = new THREE.PerspectiveCamera(
			25,
			this.sizes.width / this.sizes.height,
			0.1,
			300
		)
		this.defaultCameraPosition = new THREE.Vector3(-0.5, 1.5, 8.5)

		this.instance.position.copy(this.defaultCameraPosition)
		this.instance.lookAt(new THREE.Vector3(0, 0, 0))

		this.lerpVector.copy(this.instance.position)

		this.scene.add(this.instance)
	}

	setControls() {
		this.controls = new OrbitControls(this.instance, this.canvas)
		// this.controls = {}

		this.controls.enableZoom = true
		this.controls.minDistance = 7
		this.controls.maxDistance = 10

		this.controls.enablePan = false
		this.controls.enableDamping = true
		this.controls.enableRotate = false
		this.controls.enabled = true
		this.controls.target = new THREE.Vector3(0, 0, 0)
		this.controls.minPolarAngle = Math.PI / 2
		this.controls.maxPolarAngle = Math.PI / 2

		// this.controls.mouseButtons = {
		//     LEFT: THREE.MOUSE.ROTATE,
		//     MIDDLE: null,
		//     RIGHT: null,  // Отключает действие для правой кнопки мыши
		// };
		//
	}

	addEventListeners() {
		// Rotation
		let pointerDown = false,
			pointerX = 0,
			pointerY = 0;

		// Handlers
		this.canvas.addEventListener('touchmove', (e) => {
			if (!pointerDown) return;
			// console.log('event', e)
			let deltaX = e.targetTouches[0].clientX - pointerX;
			// let	deltaY = e.targetTouches[0].clientY - pointerY;

			pointerX = e.targetTouches[0].clientX;
			pointerY = e.targetTouches[0].clientY;

			const wrapCharacter = this.experience.world?.character?.container

			if (wrapCharacter) {
				wrapCharacter.rotation.y += deltaX * 0.02
			}
		});

		this.canvas.addEventListener('touchstart', (e) => {
			// e.preventDefault();
			pointerDown = true;
			pointerX = e.targetTouches[0].clientX;
			pointerY = e.targetTouches[0].clientY;
		});

		this.canvas.addEventListener('touchend', () => {
			// e.preventDefault();
			pointerDown = false;
		});


		// Zooming
		this.canvas.addEventListener('touchstart', (event) => {
			this.zooming = event.touches.length === 2
			if (this.zooming) {
				this.zoomAnimation?.kill()
			}
		})

		this.canvas.addEventListener('touchend', () => {
			if (!this.zooming) return

			this.zoomAnimation?.kill()

			const targetDistance = this.controls.maxDistance

			this.zoomAnimation = gsap.to(this.instance.position, {
				duration: 1,
				onUpdate: () => {
					const currentDistance = this.instance.position.distanceTo(this.controls.target)
					const newDistance = THREE.MathUtils.lerp(currentDistance, targetDistance, 0.1)

					const direction = new THREE.Vector3()
						.subVectors(this.instance.position, this.controls.target)
						.normalize()

					const newPosition = direction
						.multiplyScalar(newDistance)
						.add(this.controls.target)

					this.instance.position.copy(newPosition)

					this.controls.update()
				}
			})
		})

		// window.addEventListener('3d-camera:disable-auto-rotating', () => {
		// 	this.controls.autoRotate = false
		// 	this.instance.position.copy(this.defaultCameraPosition)
		// })

		// window.addEventListener('3d-camera:enable-auto-rotating', () => {
		// 	this.controls.autoRotate = true
		// })

		window.addEventListener('3d-camera:disable-zooming', () => {
			this.controls.enableZoom = false
			this.instance.position.copy(this.defaultCameraPosition)
		})

		window.addEventListener('3d-camera:enable-zooming', () => {
			this.controls.enableZoom = true
		})
	}

	resize() {
		this.instance.aspect = this.sizes.width / this.sizes.height
		this.instance.updateProjectionMatrix()
	}

	update() {
		this.controls.update()

		//this.instance.updateMatrixWorld() // To be used in projection
	}

	animateCameraPosition() {}
}
