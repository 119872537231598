<template>
	<AppModal
		:show="show"
		:custom-style="{ background: bgGradient }"
		customClass="modal-select-character"
		@close="emitClose"
	>
		<template #content>
			<FullScreenPreloader :loading="showPreloader" />

			<NamedBackground v-if="bgSrc" :src="bgSrc" />

			<h3 class="modal-select-character__heading modal-heading font-heading center m-0">
				{{ rig?.name_ru }}
			</h3>

			<p class="modal-select-character__variation">#{{ variationNumber }}</p>

			<div class="modal-select-character__characters">
				<CharacterModalSlider
					v-if="currentVariation"
					:rig="currentVariation?.rig"
					:show-navigation="showNavigation"
					:nav-elements="{
						prevEl: `#prev-character`,
						nextEl: `#next-character`
					}"
					@prev="handlePrevCharacter"
					@next="handleNextCharacter"
				/>
			</div>

			<div class="modal-select-character__icon">
				<IconMotion class="modal-select-character__icon" />
			</div>

			<div class="modal-select-character__actions">
				<div v-if="showNavigation" class="modal-slider__controls">
					<button
						:id="`prev-character`"
						type="button"
						class="button-transparent controls__prev"
					>
						<span class="icon">
							<IconArrow />
						</span>
					</button>

					<button
						:id="`next-character`"
						type="button"
						class="button-transparent controls__next"
					>
						<span class="icon">
							<IconArrow />
						</span>
					</button>
				</div>

				<button
					class="modal-select-character__button button button-primary"
					@click="emitConfirm"
				>
					Выбрать
				</button>

				<button
					class="text-button modal-select-character__button-cancel"
					@click="emitClose"
				>
					Назад
				</button>
			</div>
		</template>
	</AppModal>
</template>

<script setup>
import { computed, watch } from 'vue'

import useUserHeroVariations from '@/composables/useUserHeroVariations.js'

import AppModal from '@/components/AppModal.vue'
import IconMotion from '@/components/Icons/IconMotion.vue'
import CharacterModalSlider from '@/components/Character/CharacterModalSlider.vue'
import IconArrow from '@/components/Icons/IconArrow.vue'
import FullScreenPreloader from '@/components/Preloaders/FullScreenPreloader.vue'
import NamedBackground from '@/components/Character/NamedBackground.vue'

const emit = defineEmits(['close', 'confirm', 'update:currentCharacter'])

const props = defineProps({
	show: {
		type: Boolean,
		default: false
	},
	rig: {
		type: [Object, null],
		required: true
	},
	currentCharacter: {
		type: Object
	},
	showPreloader: {
		type: Boolean,
		default: false
	}
})

const { heroesCount, currentVariation, setRigId, prevVariation, nextVariation } =
	useUserHeroVariations(props.rig?.id, 0)

const showNavigation = computed(() => heroesCount.value > 1)

const variationNumber = computed(() => {
	return String(currentVariation.value?.variation_number ?? '').padStart(5, '0')
})

const bgSrc = computed(() => {
	return props.rig?.design_options?.named_bg_src
})

const bgGradient = computed(() => {
	return props.rig?.design_options?.bg_gradient
})

watch(currentVariation, (newValue) => {
	if (newValue) {
		emit('update:currentCharacter', newValue)
	}
})

watch(
	() => props.show,
	(newValue) => {
		if (newValue) {
			emit('update:currentCharacter', currentVariation.value)
		}
	}
)

watch(
	() => props.rig?.id,
	(newValue) => {
		if (newValue) {
			setRigId(newValue)
		}
	}
)

function handlePrevCharacter() {
	prevVariation()
}

function handleNextCharacter() {
	nextVariation()
}

function emitClose() {
	emit('close')
}

function emitConfirm() {
	emit('confirm')
}
</script>
