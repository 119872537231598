import { computed, watch, onMounted, onBeforeUnmount } from 'vue'

import { use3DModelsStore } from '@/stores'

export default function use3DScene(variation) {
	const threeDModelsStore = use3DModelsStore()
	const defaultCanvasClass = 'character-3d-model'
	const isPreloaderShown = computed(() => {
		return threeDModelsStore.isPreloaderModelLoading
	})
	const readyToRender = computed(() => {
		return threeDModelsStore.is3DWorldReady && variation.value
	})

	watch(readyToRender, (newValue) => {
		if (newValue) {
			threeDModelsStore.setPreloaderModelLoading(true)
			threeDModelsStore.setCanvasVisible(false)
			threeDModelsStore.load3DModels(variation.value)
			threeDModelsStore.toggleRotation(false)
		}
	})

	onMounted(() => {
		if (threeDModelsStore.is3DWorldReady && variation.value) {
			threeDModelsStore.setPreloaderModelLoading(true)
			threeDModelsStore.setCanvasVisible(false)
			threeDModelsStore.load3DModels(variation.value)
			threeDModelsStore.toggleRotation(false)
		} else {
			setTimeout(() => {
				threeDModelsStore.init3DScene()
			}, 0)
		}
	})

	onBeforeUnmount(() => {
		threeDModelsStore.setCharacter3DTranslated(false)
		threeDModelsStore.setCanvasVisible(false)
		threeDModelsStore.stopAnimation()
		threeDModelsStore.toggleRotation(false)
		threeDModelsStore.toggleModelUpdate(false)
	})

	function showPreloader(value) {
		threeDModelsStore.setPreloaderModelLoading(value)
	}

	function playAnimation(animationName) {
		threeDModelsStore.startAnimation(animationName)
	}

	function stopAnimation() {
		threeDModelsStore.stopAnimation()
	}

	function placeSceneOverModal(customCanvasClass) {
		threeDModelsStore.setCanvasClass(`${defaultCanvasClass} ${customCanvasClass} over-modal`)
	}

	function resetScenePosition() {
		threeDModelsStore.setCanvasClass(defaultCanvasClass)
	}

	function getVariationImage() {
		return threeDModelsStore.getImageUrl()
	}

	return {
		isPreloaderShown,
		showPreloader,
		playAnimation,
		stopAnimation,
		placeSceneOverModal,
		resetScenePosition,
		getVariationImage
	}
}
