import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'Home',
			component: HomeView
		},
		{
			path: '/login',
			name: 'Login',
			component: LoginView
		},
		{
			path: '/g/:code',
			name: 'Gift',
			component: () => import('@/views/GiftView.vue')
		},
		{
			path: '/webar',
			name: 'WebAR',
			component: () => import('@/views/WebARView.vue')
		},
		{
			path: '/constructor',
			name: 'Constructor',
			component: () => import('@/views/ConstructorView.vue')
		},
		{
			path: '/constructor/search',
			name: 'Constructor Search',
			component: () => import('@/views/SearchView.vue')
		},

		{
			path: '/character',
			name: 'Character',
			component: () => import('@/views/CharacterView.vue')
		},
		{
			path: '/character/search',
			name: 'Character Search',
			component: () => import('@/views/SearchView.vue')
		},
		{
			path: '/favourite',
			name: 'Favourite',
			component: () => import('@/views/FavouriteView.vue')
		},
		{
			path: '/favourite/search',
			name: 'Favourite Search',
			component: () => import('@/views/SearchView.vue')
		}
		// {
		//   path: '/about',
		//   name: 'about',
		//   // route level code-splitting
		//   // this generates a separate chunk (About.[hash].js) for this route
		//   // which is lazy-loaded when the route is visited.
		//   component: () => import('../views/AboutView.vue')
		// }
	]
})

export default router
