import { getOptions, HOST, request } from './index'

export const makeGift = async (characterId, authHeaders) => {
	const url = `${HOST}/gifts/make`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST',
			body: JSON.stringify({ character_id: characterId })
		})
		if (response.ok) {
			const data = await response.json()

			return data?.code
		}
	})
}

export const receiveGift = async (code, authHeaders) => {
	const url = `${HOST}/gifts/receive`

	return request(async () => {
		const response = await fetch(url, {
			...getOptions(authHeaders),
			method: 'POST',
			body: JSON.stringify({ code })
		})
		if (response.ok) {
			return await response.json()
		}
	})
}

export const getInfo = async (code, authHeaders) => {
	const url = `${HOST}/gifts/${code}/get_info`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			return await response.json()
		}
	})
}

export const getStatus = async (code, authHeaders) => {
	const url = `${HOST}/gifts/${code}/status`

	return request(async () => {
		const response = await fetch(url, getOptions(authHeaders))
		if (response.ok) {
			const data = await response.json()

			return data?.status
		}
	})
}
