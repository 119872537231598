import { ref, onBeforeUnmount } from 'vue'
import { useExchangeStore } from '@/stores'

export default function useExchangeReceiving(exchangeCode) {
	const exchangeStore = useExchangeStore()
	const status = ref('')
	const exchangeCharacter = ref(null)
	let exchangingInProgress = false

	async function connectToExchanging() {
		exchangingInProgress = true

		const data = await exchangeStore.connectToExchange(exchangeCode)
		if (data) {
			exchangeCharacter.value = data?.first_character
			status.value = data.status
			checkStatus()
		} else {
			status.value = 'Error'
		}
	}

	async function setCharacter(charcterId) {
		const data = await exchangeStore.setCharacter(exchangeCode, charcterId)

		if (data) {
			status.value = data.status
		} else {
			status.value = 'Error'
		}
	}

	async function stopExchanging() {
		exchangingInProgress = false
	}

	async function cancelExchanging() {
		await exchangeStore.cancelExchange(exchangeCode)
	}

	async function checkStatus() {
		const result = await exchangeStore.getStatus(exchangeCode)
		const resultStatus = result?.status

		if (resultStatus === 'Exchanged') {
			status.value = resultStatus
		} else if (exchangingInProgress) {
			switch (resultStatus) {
				case 'Pending':
					status.value = resultStatus
					setTimeout(checkStatus, 2000)
					break
				case 'Confirmation':
					status.value = resultStatus
					setTimeout(checkStatus, 2000)
					break
				case 'Canceled':
				case 'CharacterExist':
					status.value = resultStatus
					break
				default:
					status.value = 'Error'
			}
		}
	}

	onBeforeUnmount(() => {
		if (exchangingInProgress.value) {
			cancelExchanging()
		}
	})

	return {
		status,
		exchangeCharacter,
		connectToExchanging,
		setCharacter,
		stopExchanging,
		cancelExchanging
	}
}
