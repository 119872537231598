import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from '../State.js'
import Materials from '../Materials/Materials.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'
import Resources from '../Utils/Resources.js'

export default class Body extends Model {
	experience = Experience.getInstance()
	debug = Debug.getInstance()
	state = State.getInstance()
	materials = Materials.getInstance()
	scene = experience.scene
	time = experience.time
	camera = experience.camera.instance
	cameraClass = experience.camera
	renderer = experience.renderer.instance
	resources = experience.resources
	container = new THREE.Group()

	constructor() {
		super()

		this.sources = []
		this.localResourcesCache = {
			models: [],
			textures: [],
			roughnessTextures: [],
			normalTextures: []
		}
		this.sourcesReady = 'bodyReady'
		this.setDebug()
	}

	setModel(localResources) {
		this.sourceBody = localResources.items.bodyModel
		this.sourceBodyTexture = localResources.items.bodyTexture

		if (this.sourceBodyTexture) {
			this.sourceBodyTexture.colorSpace = THREE.SRGBColorSpace
		}

		this.sourceBodyRoughnessTexture = localResources.items.bodyRoughnessTexture
		this.sourceBodyNormalTexture = localResources.items.bodyNormalTexture
		this.sourceBodyAlphaMap = localResources.items.bodyAlphaMap

		this.bodyModel = this.sourceBody.scene
		this.bodyModel.bindMode = THREE.DetachedBindMode
		this.bodyModel.traverse((child) => {
			if (child.isMesh) {
				child.material.map = this.sourceBodyTexture
				child.material.roughness = 1
				child.material.roughnessMap = this.sourceBodyRoughnessTexture
				child.material.normalMap = this.sourceBodyNormalTexture
				child.material.transparent = true
				child.material.alphaMap = this.sourceBodyAlphaMap
				child.material.needsUpdate = true
			}
		})

		this.container.name = 'Body'
		this.container.add(this.bodyModel)
	}

	setSharedBones(sharedSkeleton) {
		this.bodyModel?.traverse((object) => {
			if (object instanceof THREE.SkinnedMesh) {
				object.bind(sharedSkeleton, new THREE.Matrix4())
			}
		})
	}

	clear() {
		this.sources = []
		this.bodyModel = null
	}

	resize() {}

	// setDebug() {
	// 	if (!this.debug.active) return
	// }

	update(deltaTime) {}
}
