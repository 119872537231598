import * as THREE from 'three'
import Model from './Abstracts/Model.js'
import Experience from '../Experience.js'
import Debug from '../Utils/Debug.js'
import State from '../State.js'
import Materials from '../Materials/Materials.js'
import * as SkeletonUtils from 'three/addons/utils/SkeletonUtils.js'
import Resources from '../Utils/Resources.js'

export default class Accessory extends Model {
	experience = Experience.getInstance()
	debug = Debug.getInstance()
	state = State.getInstance()
	materials = Materials.getInstance()
	scene = experience.scene
	time = experience.time
	camera = experience.camera.instance
	cameraClass = experience.camera
	renderer = experience.renderer.instance
	resources = experience.resources
	container = new THREE.Group()

	constructor() {
		super()

		this.sources = []
		this.localResourcesCache = {
			models: [],
			textures: [],
			roughnessTextures: [],
			normalTextures: []
		}
		this.sourcesReady = 'accessoryReady'
		this.setDebug()
	}

	setModel(localResources) {
		this.sourceAccessory = localResources.items.accessoryModel
		this.sourceAccessoryTexture = localResources.items.accessoryTexture
		this.sourceAccessoryRoughnessTexture = localResources.items.accessoryRoughnessTexture
		this.sourceAccessoryNormalTexture = localResources.items.accessoryNormalTexture
		this.sourceAccessoryAlphaMap = localResources.items.accessoryAlphaMap
		this.accessoryModel = this.sourceAccessory.scene
		this.accessoryModel.bindMode = THREE.DetachedBindMode
		this.accessoryModel.traverse((child) => {
			if (child.isMesh) {
				child.material.map = this.sourceAccessoryTexture
				child.material.roughness = 1
				child.material.roughnessMap = this.sourceAccessoryRoughnessTexture
				child.material.normalMap = this.sourceAccessoryNormalTexture
				child.material.transparent = true
				child.material.alphaMap = this.sourceAccessoryAlphaMap
				child.material.needsUpdate = true
			}
		})

		this.container.name = 'Accessory'
		this.container.add(this.accessoryModel)
	}

	setSharedBones(sharedSkeleton) {
		this.accessoryModel?.traverse((object) => {
			if (object instanceof THREE.SkinnedMesh) {
				object.bind(sharedSkeleton, new THREE.Matrix4())
			}
		})
	}

	clear() {
		this.sources = []
		this.accessoryModel = null
	}

	resize() {}

	// setDebug() {
	// 	if (!this.debug.active) return
	// }

	update(deltaTime) {}
}
