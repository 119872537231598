<template>
	<AppModal :show="show" :customClass="'modal-login'">
		<template #content>
			<AppLoginForm @change-step="handleStepChange">
				<template #heading>
					<h3 class="login-form__heading modal-heading font-heading center uppercase m-0">
						{{ modalTitle }}
					</h3>
				</template>

				<template #first-step-content>
					<p class="login-form__note modal-text center">
						Созданная учётная запись предоставляет доступ к настройкам или возможностям
						того или иного ресурса.
					</p>
				</template>

				<template #sign-up-step-content>
					<p class="login-form__note modal-text center">
						На указанный номер было отправлено SMS с кодом.
					</p>

					<p class="login-form__note modal-text center">
						Чтобы завершить подтверждение номера, введите 6-значный код активации:
					</p>
				</template>

				<template #sign-in-step-content>
					<p class="login-form__note modal-text center">
						Чтобы войти, введите пароль от аккаунта:
					</p>
				</template>
			</AppLoginForm>
		</template>
	</AppModal>
</template>

<script setup>
import { nextTick, ref, watchEffect } from 'vue'
import AppModal from '@/components/AppModal.vue'
import AppLoginForm from '@/components/Login/AppLoginForm.vue'

defineProps({
	show: {
		type: Boolean,
		default: false
	}
})

const modal = ref(null)
const modalTitle = ref('')
const step = ref('')

watchEffect(() => {
	switch (step.value) {
		case 'first':
		case 'signUp':
			modalTitle.value = 'Регистрация'
			break
		case 'signIn':
			modalTitle.value = 'Вход'
	}
})

const emit = defineEmits(['close'])

const emitClose = () => {
	emit('close')
}

const getModalContainer = async () => {
	await nextTick()
	return modal.value.getModalContainer()
}

const handleStepChange = (value) => {
	step.value = value
}

defineExpose({
	getModalContainer
})
</script>
