import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getCurrency } from '../api/api-profile'
import { useAuthStore } from './auth-store'

export const useProfileStore = defineStore('profileStore', () => {
	const currency = ref(null)
	const isLoading = ref(false)

	const authStore = useAuthStore()

	const fetchCurrency = async () => {
		if (!authStore.isAuthenticated) {
			return
		}

		isLoading.value = true

		currency.value = await getCurrency(authStore.createAuthHeaders())

		isLoading.value = false
	}

	return {
		currency,
		isLoading,
		fetchCurrency
	}
})
